import { Box, Popover, styled } from '@mui/material';
import React, { memo } from 'react';
import colorVariables from "../../../style/sass/_colors.scss";
import MenuItems from '../menuItems';

const SCAIPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-paper': {
        backgroundColor: theme.palette.primary.background,
        color: colorVariables.greyColor3,
        borderRadius: "0.8rem",
        border: `0.15rem solid ${theme.palette.primary.borderColor}`
    },
}));

const MenuPopOver = ({
    menuData,
    open = true,
    anchorEl,
    handleClose
}) => {
    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'simple-popover' : undefined;
    return (
        <SCAIPopover
            id={id}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box
                sx={{
                    minWidth: "19rem",
                    maxHeight: "auto",
                }}
            >
                <MenuItems menuData={menuData} open={open} isPopOver={true} />
            </Box>
        </SCAIPopover>

    )
}

export default memo(MenuPopOver);