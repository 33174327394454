import { DS } from "../../../../assets/svg-icons";

export const DS_DASHBOARD = {
    name: "DS Dashboard",
    shortName: "DS",
    icon: DS,
    route: "/admin/ds-dashboard",
    permissions: ["DS_INTERNAL_DASHBOARD_VIEW"],
    extraRoutes: [
        {
            route: "/admin/details/:id",
            permissions: ["DS_INTERNAL_DASHBOARD_VIEW"],
            roles: [],
        }
    ]
}