import { createTheme } from "@mui/material";

export const customTheme = {
  light: createTheme({
    palette: {
      primary: {
        main: "#f5f7fa",
        background: "#F8FAFB",
        textColor: "#525252",
        activeText: "#282A2A",
        borderColor: "#D0D4DA",
        tooltipBackground: "#07080B",
        tooltipBorder: "1A1B1D",
        activeBorderColor: "#E94F1C",
        iconColor: "#666D80",
      },
      brandColor: {
        main: "#FF6D33",
        grey3: "#898A93",
      },
    },
    typography: {
      fontFamily: "Euclid Circular Regular, sans-serif",
      fontSize: 12,
      fontWeightMedium: "bold",
      fontWeightRegular: "lighter",
    },
    components: {
      MuiDrawer: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  }),
  dark: createTheme({
    palette: {
      primary: {
        main: "#f5f7fa",
        background: "#1D1D1D",
        textColor: "#A8A8A8",
        activeText: "#FFFFFF",
        borderColor: "#313133",
        tooltipBackground: "#07080B",
        tooltipBorder: "1A1B1D",
        activeBorderColor: "#E94F1C",
        iconColor: "#909090",
      },
      brandColor: {
        main: "#FF6D33",
        grey3: "#898A93",
      },
    },
    typography: {
      fontFamily: "Euclid Circular Regular, sans-serif",
      fontSize: 12,
      fontWeightMedium: "bold",
      fontWeightRegular: "lighter",
    },
    components: {
      MuiDrawer: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  }),
};

export const customThemePublic = {
  light: createTheme({
    components: {
    MuiCssBaseline: {
      styleOverrides: {
        "input::placeholder": {
          fontFamily: "Euclid Circular Regular, sans-serif",
        },
      },
    },
    },
    palette: {
      primary: {
        main: "#FF6D33",
        light: "#FE8056",
      },
      secondary: {
        main: "#07080B",
        light: "#525252",
      },
      custom: {
        offWide: "#F9FAFB",
        inputBorder: " #E0E3EA",
        inputColor: "#1A1C1E",
        forgotPasswordColor: "#2176FF",
        errorColor: "#E85353",
        blackCard: "#131212",
        borderCardColor: "#D0D4DA0D",
        cardPara1Color: "#B0B0B0",
        cardPara2Color: "#727477",
        borderColor: "#D0D4DA",
        touchBottom: "#BABABA",
      },
      shadow: {
        login:
          "0px 6px 12px 0px #6969691A, 0px 22px 22px 0px #69696917, 0px 50px 30px 0px #6969690D, 0px 89px 36px 0px #69696903, 0px 139px 39px 0px #69696900",
        feature:
          "0px -2px 5px 0px #0F0F0FFA, 0px -8px 8px 0px #0F0F0FD9, 0px -19px 11px 0px #0F0F0F80, 0px -34px 13px 0px #0F0F0F26, 0px -53px 15px 0px #0F0F0F05",
        testDrive:
          "0px 5px 10px 0px #0000001A, 0px 19px 19px 0px #00000017, 0px 42px 25px 0px #0000000D, 0px 75px 30px 0px #00000003, 0px 117px 33px 0px #00000000",
        innovate: "0px 5px 10px rgb(0 0 0 / 25%)",
      },
      widthCustom: {
        maxWidthContainer: "110rem",
        halfWidthContainer: "60rem",
      },
      customFontFamily: {
        secondary: "Euclid Circular Light, sans-serif",
      },
    },
    typography: {
      fontFamily: "Euclid Circular Regular, sans-serif",
      h5: {
        fontSize: 35,
        fontWeight: 300,
        color: "#ffffff",
        lineHeight: 1.1,
      },
      h4: {
        fontSize: 50,
        fontWeight: 300,
        color: "#07080B",
        lineHeight: 1.1,
      },
      h3: {
        fontSize: 20,
        color: "#07080B",
      },
      h2: {
        fontSize: 35,
        color: "#07080B",
        fontWeight: 800,
      },
      body1: {
        fontSize: 14,
        color: "#525252",
      },
      body2: {
        fontSize: 11,
        color: "#525252",
        fontFamily: "Euclid Circular Light, sans-serif",
      },
    },
  }),
};
