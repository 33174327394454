import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

function MyCard({ data }) {
  return (
    <Card
      sx={{
        background: (theme) => theme.palette.custom.blackCard,
        padding: "1.5rem 1rem",
        border: (theme) => `1px solid ${theme.palette.custom.borderCardColor}`,
        borderRadius: "0",
        height: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <CardMedia
        sx={{ height: "16rem" }}
        image={data.img}
        title="green iguana"
      />
      <CardContent sx={{ pb: "0 !important" }}>
        <Typography
          gutterBottom
          component="div"
          sx={{
            fontSize: "2rem",
            color: (theme) => theme.palette.common.white,
            textAlign: "left",
          }}
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: (theme) => theme.palette.custom.cardPara1Color,
          }}
        >
          {data.p1}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.1rem",
            color: (theme) => theme.palette.custom.cardPara2Color,
            mt: "1.5rem",
            mb: ".5rem",
            ml: ".2rem",
          }}
        >
          {data.p2}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.1rem",
            ml: ".2rem",
            color: (theme) => theme.palette.custom.cardPara2Color,
          }}
        >
          {data.p3}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MyCard;
