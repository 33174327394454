import { AppBar, Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import images from "../../constant/images";
import TypingAnimation from "../typing-animation";
import { menuRoutes } from "../../constant/constant";
import PrimaryBtn from "../button/PrimaryBtn";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { isDemoAvl } from "../../utils/ApiHandler";

function Header() {
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 50) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <motion.nav
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{
        duration: 0.35,
        ease: "easeInOut",
        type: "spring",
        damping: 12,
        stiffness: 280,
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          background: (theme) => theme.palette.common.white,
          boxShadow: "none",
        }}
      >
        <Grid
          key="modal"
          container
          sx={{
            maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
            margin: "0px auto",
            padding: "1.5rem 1rem",
          }}
        >
          <Grid item xs={4}>
            <Stack direction="row" alignItems="center">
              <Box component="img" src={images.logo} alt="log" />
              <Typography
                sx={{
                  ml: ".5rem",
                  lineHeight: 1,
                  "& .typing-text": {
                    fontSize: "2.5rem",
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                <TypingAnimation text="SCAi" />
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3rem",
            }}
          >
            {menuRoutes.map(({ name, path, isNavigate }) => {
              return (
                <Typography
                  component="a"
                  key={name}
                  href={isNavigate ? path : `#${path}`}
                  target={isNavigate ? "_blank" : "_self"}
                  sx={{
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    "&:hover": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  {name}
                </Typography>
              );
            })}
          </Grid>

          <Grid item xs={4} sx={{ textAlign: "right" }}>
            {isDemoAvl && (
              <Box component="a" href="#demo">
                <PrimaryBtn
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Schedule a Demo
                </PrimaryBtn>
              </Box>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </motion.nav>
  );
}

export default Header;
