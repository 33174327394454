// eslint-disable-next-line import/no-anonymous-default-export

import { PAI } from "../utils/RouteHelper/IBP/PAI";
import { SNOP } from "../utils/RouteHelper/IBP/SNOP";
import images from "./images";

/* 
List of All the literals and constants used in Project
*/
const constKeys = {
  api: "",
  API_METHOD_POST: "POST",
  API_METHOD_GET: "GET",
  API_METHOD_PUT: "PUT",
  API_METHOD_PATCH: "PATCH",
  API_METHOD_DELETE: "DELETE",
  API_METHOD_HEAD: "HEAD",
  FORM_VALIDATION_EXAMPLE: "Form Inputs Validation Example",
  ERROR_BOUNDARY_EXAMPLE: "Error Boundary Example",
  API_RESPONSE_ERROR: "Something went wrong with API",
  GLOBAL_ERROR: "Something went wrong",
};

export const supportedLanguage = ["en-US"];

export const adminType = {
  "3SCAdmin": "3SCAdmin",
  "Client Admin": "Client Admin",
};

export const createThemeSelect = (theme) => {
  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export const userEditInitialValues = {
  organization: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile_country_code: "",
  mobile: "",
  team: "",
  business_unit: "",
  address_line1: "",
  address_line2: "",
  country: null,
  state: null,
  city: null,
  pincode: "",
  roles: "",
};

export const getInTouch = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  organization: "",
  designation: "",
  date: "",
  time: "",
}

export const phoneInputValidateMsg = "This field is mandatory";
export const activateSNOPMessage = "Activate the SNOP to access";
export const ENABLE_FOR_MENU = [SNOP.name, PAI.name];
export const nameValidation = [
  "required",
  "alpha_num_dash_space",
  { max: "64" },
];
export const APPBAR_MENU = {
  IBP: "Integrated Business Planning",
  EDM: "Enterprise Data Management",
  DTRM: "Risk Management & Digital Twin",
  ID: "Internal Dashboard",
  CX: "CarbonX",
};
export default constKeys;

export const colorPalette = {
  grey: "#72757a",
  green: "#1BD365",
};

export const menuRoutes = [
  {
    name: "Home",
    path: "",
    isNavigate: false
  },
  {
    name: "About",
    path: "https://3scsolution.com/about-us",
    isNavigate: true,
  },
  {
    name: "Features",
    path: "features",
    isNavigate: false
  },
  {
    name: "How it works?",
    path: "works",
    isNavigate: false
  },
];

export const aboutAccordionData = [
  {
    panel: 1,
    title: "Forward-looking Visibility",
    content:
      "Deviations in the supply chain process, the exceptions are not to be seen far enough in advance to correct the root cause in many supply chain planning process.",
  },
  {
    panel: 2,
    title: "Network-wide Transparency",
    content:
      "Supply Chain partners in the extended supply chain have blind spots. A small deviations from plan at one end of the supply chain have large and costly effects up and down stream which is known as bullwhip effect.",
  },
  {
    panel: 3,
    title: "Reactive Management",
    content:
      "The late notifications and identification of unplanned events drives reactive management of likely impacts. Planners loose productive time tending to low value adding exceptions leading customers to substitute brands or make purchases from competitor.",
  },
  {
    panel: 4,
    title: "Demand Uncertainty",
    content:
      "The uncertainty in demand leads to excess inventory and the uncertainty in supply leads to speculative ordering. It creates deployment issues in determining where, and what quantity of inventory to deploy in the network, across dimensions of time, geography and demographics.",
  },
  {
    panel: 5,
    title: "Sub-optimal Plan",
    content:
      "The lack of planning into stock levels up and downstream leads to shortages followed by overcompensation & excesses. Sometime this leads to increased level of discounting or write-offs for slow-moving or obsolete inventory.",
  },
  {
    panel: 6,
    title: "Data Availability",
    content:
      "Critical and vital information is not available when needed resulting in lost sales, out-of-stocks and unnecessary mark downs and reduction.",
  },
];

export const featureCardData = [
  {
    img: images.card1,
    title: "Explainable AI",
    p1: "Build trust and transparency in your supply chain with Explainable AI, offering clarity behind every prediction.",
    p2: "•⁠  ⁠Uncovers the causal factors behind predictions to provide actionable insights.",
    p3: "•⁠  ⁠Equips planners with the information they need to make confident, informed decisions. ",
  },
  {
    img: images.card2,
    title: "Agentic AI",
    p1: "Leverage Agentic AI to transform your supply chain planning with real-time adaptability and pre-emptive insights for optimal outcomes. ",
    p2: "•⁠  Adapts dynamically to changing business conditions by autonomously selecting models and strategies.",
    p3: "•⁠  ⁠Enables proactive decision-making by identifying risks and opportunities ahead of time.  ",
  },
  {
    img: images.card3,
    title: "Rapid 12 Week Deployment",
    p1: "Accelerate your AI deployment with modular design, enabling rapid implementation and incremental scaling. ",
    p2: "•⁠  ⁠Speeds up application delivery with a 12-week configuration process. ",
    p3: "•⁠  ⁠Allows businesses to scale and adapt the solution incrementally with minimal disruption to daily operations. ",
  },
];

export const featureCardSeamlessData = [
  {
    img: images.card4,
    title: "Seamless Integration",
    p1: "Seamless integration unifies your systems, ensuring uninterrupted data flow and streamlined operations across your enterprise. ",
    p2: "•⁠  ⁠Connects effortlessly with ERP systems and other enterprise tools to streamline workflows.  ",
    p3: "•⁠  ⁠Enables real-time data synchronization, ensuring a single source of truth for accurate decision-making. ",
  },
  {
    img: images.card5,
    title: "Collaborative Exception Management",
    p1: "Empower your planners with a collaborative platform to tackle exceptions quickly and efficiently, supported by AI insights.",
    p2: "•⁠  Provides a shared workspace for teams to resolve critical exceptions in real time. ",
    p3: "•⁠  ⁠Supports planners with AI-driven alerts that prioritize and address the most urgent issues.  ",
  },
];

export const monitorData = [
  {
    title: "Monitor your Supply Chain",
    par: "Monitor module gives planner high level insights on",
    list: [
      "• Stressed out Product Categories",
      "• Regions & Locations with imbalances",
      "• Forward looking potential value leakages",
      "• Magnitude of $ Value Leakage by Products, Categories, Regions & Nodes",
      "• In nutshell, planner gets areas to focus to plug value leakages",
    ],
  },
  {
    title: "Detect Exceptions Pro-actively",
    par: "Detect module enables planner to exactly see",
    list: [
      "• Which Product SKU is at problem?",
      "• Which all Locations causing problems for the SKU?",
      "• When this problem is going to impact your supply chain?",
      "• How big is the problem for the given SKU x Location?",
      "• Why there is a problem at this location for this SKU?",
    ],
  },
  {
    title: "Diagnose for Causes",
    par: "Diagnose module helps with the explain-ability by providing",
    list: [
      "• A digital-twin of the supply chain for given Product",
      "• Details of Problem & its root cause",
      "• Causal Factors contributing to the root-cause",
      "• Ability to simulate various actions & what-if analysis",
      "• Get better understanding of various forces at play",
    ],
  },
  {
    title: "Correct through Actions",
    par: "Correct module empowers planner to",
    list: [
      "• Work on mechanisms to mitigate exceptions",
      "• Accept system generated Recommendations for actions",
      "• Generate custom actions to mitigate exceptions",
      "• Collaborate with other planners",
      "• Define strategies to prevent risk from appearing again",
    ],
  },
  {
    title: "Commit to your System of Execution",
    par: "Commit to your System of Execution",
    list: [
      "• Integration & Automation capabilities",
      "• Planners can push mitigation actions to underlying ERP or System of execution",
      "• Visibility into what got executed & what is pending.",
      "• Value attribution to actions & value tracking",
    ],
  },
];
