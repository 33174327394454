import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import "font-awesome/css/font-awesome.min.css";
import { default as React } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './i18n';
import "./index.css";

const nonce = document.querySelector('meta[property="csp-nonce"]')?.getAttribute('content') || "";
const emotionCache = createCache({
  key: 'csp-nonce',
  nonce: nonce,
  prepend: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <CacheProvider value={emotionCache}>
    <App />
  </CacheProvider>
  // </React.StrictMode>
);