import { useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

export default function ScrollContainer({ children }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  useParallax(scrollYProgress, 300);

  return (
    <section className="img-container">
      <div ref={ref}>{children}</div>
    </section>
  );
}

export function StyleSheet() {
  return (
    <style>{`
         html {
            // scroll-snap-type: y mandatory;
        }

        .img-container {
            // scroll-snap-align: start;
            position: relative;
        }
    `}</style>
  );
}
