import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CustomInput from "../InputField/CustomInput";
import PrimaryBtn from "../button/PrimaryBtn";
import { currentYear } from "../../helper/commonHelper";
import { getInTouch } from "../../constant/constant";
import CustomPhoneInput from "../CustomPhoneInput";
import SimpleReactValidator from "simple-react-validator";
const validator = new SimpleReactValidator();

function TouchForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [_, setForceUpdate] = useState(false);
  const [formData, setFormData] = useState(getInTouch);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ formData });
  };
  return (
    <>
      <Box
        onSubmit={handleSubmit}
        component="form"
        noValidate
        autoComplete="off"
      >
        <Grid container gap={3}>
          <Grid item xs={5.7}>
            <CustomInput
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={5.7}>
            <CustomInput
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomPhoneInput
              placeholder="Contact Number**"
              className="scai-phone-input"
              name={"Mobile"}
              value={formData.mobile}
              onChange={(val) => setFormData({ ...formData, mobile: val })}
              validator={validator}
              validations={["required", "phone", { max: "13" }]}
            />
          </Grid>
          <Grid item xs={5.7}>
            <CustomInput
              label="Organization"
              name="organization"
              value={formData.organization}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={5.7}>
            <CustomInput
              label="Designation"
              name="designation"
              value={formData.designation}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={5.7}>
            <CustomInput label="Preferable Date For Demo" />
          </Grid>
          <Grid item xs={5.7}>
            <CustomInput label="At What Time ?" />
          </Grid>
          <Grid item xs={12}>
            <PrimaryBtn
              size="small"
              sx={{
                fontFamily: (theme) => theme.palette.customFontFamily.secondary,
                fontSize: "1rem",
                textTransform: "capitalize",
                letterSpacing: ".1rem",
                width: "100%",
                justifyContent: "space-between",
              }}
              endIcon={<ArrowOutwardIcon />}
              type="submit"
            >
              Schedule My Demo
            </PrimaryBtn>
          </Grid>
        </Grid>
      </Box>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontFamily: (theme) => theme.palette.customFontFamily.secondary,
          color: (theme) => theme.palette.custom.touchBottom,
          textAlign: "right",
          mt: "auto",
        }}
      >
        Made in India for the World. © {currentYear()}. All rights reserved. 3SC
        Solutions
      </Typography>
    </>
  );
}

export default TouchForm;
