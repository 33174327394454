import React, { createContext, useReducer } from "react";

export const ACTION_TYPE = {
  SCROLL: "SCROLL",
};
export const TasksContext = createContext(null);
export const TasksDispatchContext = createContext(null);

function MyContext({ children }) {
  const [tasks, dispatch] = useReducer(tasksReducer, initialTasks);
  return (
    <TasksContext.Provider value={tasks}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksContext.Provider>
  );
}

export default MyContext;

function tasksReducer(tasks, action) {
  switch (action.type) {
    case ACTION_TYPE.SCROLL:
      return { ...tasks, ...action.payload };
    default:
      throw new Error(`${ERROR_MSG.UNHANDLED}: ${action}`);
  }
}

const initialTasks = {
  isFeatureEntered: true,
  isMenuClicked: false,
};
