import { FormLabel, InputBase, Stack, styled } from "@mui/material";
import React from "react";

const WrapperInputBase = styled(InputBase)(({ theme }) => ({
  borderBottom: `.1rem solid ${theme.palette.custom.inputBorder}`,
  padding: "1.4px .5rem",
  fontSize: "1.2rem",
  color: theme.palette.custom.inputColor,
  width: "100%",
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.custom.inputColor,
    fontSize: "1.2rem",
  },
  "&:hover": {},
  "& .Mui-focused": {
    backgroundColor: "transparent",
  },
  "& input::placeholder": {
    color: theme.palette.custom.inputColor,
    opacity: 1,
  },
  "& input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 100px transparent inset",
    WebkitTextFillColor: "inherit",
    WebkitBackgroundClip: "text",
  },
  "& input": {
    backgroundColor: "transparent",
  },
}));

function CustomInput({ label, ...otherProps }) {
  return (
    <Stack>
      {label && (
        <FormLabel
          sx={{
            fontSize: "1.4rem",
            fontFamily: (theme) => theme.palette.customFontFamily.secondary,
            color: (theme) => theme.palette.custom.cardPara2Color,
          }}
        >
          {label}
        </FormLabel>
      )}
      <WrapperInputBase autoFocus={false} {...otherProps} />
    </Stack>
  );
}

export default CustomInput;
