import { Box, Grid } from "@mui/material";
import React from "react";
import { featureCardSeamlessData } from "../../constant/constant";
import MyCard from "./MyCard";

function FeatureCardSeamless() {
  return (
    <Box>
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          px: "1rem",
          pb: ".2rem",
        }}
      >
        <Grid
          container
          alignItems="stretch"
          sx={{
            boxShadow: (theme) => theme.palette.shadow.feature,
            minHeight: "36.1rem",
          }}
        >
          {featureCardSeamlessData.map((item, idx) => {
            return (
              <Grid item xs={6} key={idx}>
                <MyCard data={item} isSeamless />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default FeatureCardSeamless;
