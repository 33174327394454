import { Notification } from '@3sc/common-component';
import { Box, ListItemButton, ListItemText, Stack, styled, Tooltip, useTheme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import { tooltipClasses } from '@mui/material/Tooltip';
import Cookies from "js-cookie";
import React from 'react';
import Logo from "../../../assets/svg-icons/logo.svg";
import { user_metadata } from '../../../config/localStorage';
import { accessibleMenus, redirectToPath } from '../../../helper/com-helper';
import { isNotificationEnabled } from '../../../helper/commonHelper';
import "../../../style/notificationCommonComp.scss";
import { alertApiEndpoint } from '../../../utils/ApiHandler';
import { BAR_MENU } from '../../../utils/const';
import MultiAvatar from '../../MultiAvatar';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.background, // Background color
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`, // Bottom border
    color: theme.palette.primary.textColor, // Default text and icon color
    boxShadow: 'none', // Removed box shadow
    minHeight: '4rem', // AppBar height
    top: 0,
    left: 0,
    right: 0,
    '& .MuiToolbar-root': {
        minHeight: '4rem', // Toolbar height matches AppBar
        display: 'flex',
        //justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
    },
    '& .MuiTypography-root, & .MuiIconButton-root': {
        color: theme.palette.primary.textColor, // Default text and icon color
        '&:hover': {
            color: theme.palette.primary.activeText, // Hover text and icon color
        },
    },
}));
const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        //color: theme.palette.common.black,
        color: theme.palette.primary.tooltipBackground,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.tooltipBackground,
        border: `0.1rem solid ${theme.palette.primary.tooltipBorder}`,
        fontSize: "0.8rem"
    },
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme, open }) => ({
    padding: 0,
    textAlign: 'center',
    position: 'relative', // To position the underline
    '&:hover': {
        backgroundColor: theme.palette.action.hover, // Optional hover effect
    },
}));

const TopNav = ({ selectedMenu }) => {
    const theme = useTheme();
    const containerMenu = accessibleMenus(Object.values(BAR_MENU));
    const user = JSON.parse(Cookies.get("user") || null);
    const logo = user?.logo ?? null;
    const clientName = user_metadata?.tenant_name;
    return (
        <AppBar position="fixed">
            <Toolbar>
                <Box className="logo-section">
                    <CustomTooltip
                        title={clientName === "" ? "3SC" : clientName}
                        arrow
                        placement="right"
                    >
                        <img src={logo ?? Logo} alt="Logo" />
                    </CustomTooltip>
                </Box>
                <Stack direction="row" justifyContent={"space-between"} sx={{
                    height: "4rem",
                    width: "100%",
                    alignItems: "center",
                    borderLeft: `1px solid ${theme.palette.primary.borderColor}`,
                    padding: "0.2rem 0.5rem"
                }}>
                    {/* Container's menu */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            padding: '0rem',
                        }}
                    >
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 0,
                                height: "4rem"
                            }}
                        >
                            {containerMenu.map((menu, index) => {
                                if(menu.name === "Internal Dashboard") return null;
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            margin: "0 0.6rem",
                                            borderBottom: selectedMenu?.parent === menu?.name ? `0.18rem solid ${theme.palette.primary.activeBorderColor}` : "none"
                                        }}
                                    >
                                        <ListItemButtonStyled
                                            sx={{
                                                padding: "0 1rem",
                                                width: "100%",
                                                height: "100%"
                                            }}
                                            onClick={() => redirectToPath(menu)}
                                        >
                                            <ListItemText
                                                primary={menu?.name}
                                                sx={{
                                                    transition: "color 0.3s ease",
                                                    textAlign: "center",
                                                }} // Optional: Keep alignment here
                                                primaryTypographyProps={{
                                                    fontSize: "1.1rem", // Set your desired font size
                                                    color:
                                                        selectedMenu?.parent === menu?.name
                                                            ? `${theme.palette.primary.activeText}!important`
                                                            : `${theme.palette.primary.textColor}`, // Optional: Add color here
                                                }}
                                            />
                                        </ListItemButtonStyled>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                    {/* Logout & user section */}
                    <Box sx={{
                        margin: "1rem"
                    }}>
                        <Stack direction={"row"} gap={"1.4rem"}>
                            {isNotificationEnabled() && (
                                <Notification baseUrl={alertApiEndpoint} />
                            )}
                            <MultiAvatar />
                        </Stack>
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default TopNav