import { Box, Grid } from "@mui/material";
import React from "react";
import MyCard from "./MyCard";
import { featureCardData } from "../../constant/constant";

function FeatureCard() {
  return (
    <Box>
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          px: "1rem",
        }}
      >
        <Grid container>
          {featureCardData.map((item, idx) => {
            return (
              <Grid item xs={4} key={idx}>
                <MyCard data={item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default FeatureCard;
