import { Backdrop, Divider, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import React from 'react';
import { Toggle } from "../../../assets/svg-icons";
import useMenuData from '../../../hooks/useMenuData';
import MenuItems from '../menuItems';

const drawerWidth = 25; // Drawer width when open (in rem)
const closedDrawerWidth = 4; // Drawer width when closed (in rem)

const DrawerHeader = styled('div')(({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-end' : 'center', // Align icon based on drawer state
    padding: open ? theme.spacing(0, 1.2) : 0, // No padding when closed
    height: '3.5rem', // Matches AppBar height
    color: open ? theme.palette.primary.activeText : theme.palette.primary.textColor, // Text/icon color
    '& .MuiIconButton-root': {
        color: open ? theme.palette.primary.activeText : theme.palette.primary.textColor, // Icon color matches the state
    },
    width: '100%', // Ensure it spans the full drawer width
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        zIndex: theme.zIndex.drawer,
        position: "fixed",
        top: '4rem', // Align with AppBar height
        left: 0, // Start at the left edge
        width: open ? `${drawerWidth}rem` : `${closedDrawerWidth}rem`, // Dynamic drawer width
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        // marginTop: '4rem', // Offset by AppBar height
        height: `calc(100% - 3.5rem)`, // Adjust height for AppBar
        '& .MuiDrawer-paper': {
            position: 'fixed', // Ensure the paper component is also fixed
            top: '4rem',
            left: 0,
            // marginTop: '4rem',
            height: `calc(100% - 3.5rem)`,
            width: open ? `${drawerWidth}rem` : `${closedDrawerWidth}rem`,
            backgroundColor: theme.palette.primary.background, // Background color
            borderRight: `1px solid ${theme.palette.primary.borderColor}`, // Border color
            color: theme.palette.primary.textColor, // Default text and icon color
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    })
);

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer - 1, // Ensure backdrop is behind the drawer
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    backdropFilter: 'blur(2px)', // Add blur effect
}));

const SideNav = ({ open, handleDrawerToggle, handleCloseClick, selectedMenu }) => {
    const menuDatas = useMenuData(selectedMenu);
    const theme = useTheme();

    return (
        <>
            {/* Backdrop */}
            <CustomBackdrop open={open} onClick={handleCloseClick} />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader open={open} className={open ? `opened-drawer` : ''}>
                    {open && (
                        <Typography
                            className="close-text"
                            onClick={handleCloseClick}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            {"Close"}
                        </Typography>
                    )}
                    <IconButton onClick={handleDrawerToggle}>
                        <Toggle color={theme.palette.primary.textColor} />
                    </IconButton>
                </DrawerHeader>
                <Divider variant="middle" sx={{
                    borderWidth: "0.095rem",
                    mt: "0.2rem",
                    borderColor: `${theme.palette.primary.borderColor}!important`,
                    backgroundColor: `${theme.palette.primary.borderColor}!important`,
                    opacity: 1
                }} />
                <Stack direction={"column"} justifyContent={"space-between"} sx={{
                    height: "100vh"
                }}>
                    <MenuItems menuData={menuDatas?.menu} open={open} selectedMenu={selectedMenu} />
                </Stack>
            </Drawer>
        </>
    )
}

export default SideNav