import { Profile } from "../../../assets/svg-icons";
import { ROUTES_PATH } from "../../EndPoints";

export const PROFILE_ROUTE = [
  {
    name: "My Profile",
    icon: Profile,
    shortName: "Profile",
    route: ROUTES_PATH.MY_PROFILE,
    permissions: [],
  },
];
