import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import InfiniteTextAnimation from "./InfiniteTextAnimation";
// import OutlinedText from "./OutlinedText";
const phrases = [1, 2, 3];

function BannerText() {
  const [index, setIndex] = useState(0);
  const text = "Smart Future Planning".split(" ");

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Typography
      variant="h4"
      color="primary.main"
      sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
        "& h2": {
          fontSize: "5rem",
          fontWeight: "bold",
        },
      }}
    >
      {index === 0 ? <InfiniteTextAnimation text="Predict Accurately" /> : null}
      {index === 1 ? (
        <motion.div
          key={1}
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
          transition={{
            delay: 0.5,
            duration: 1.3,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
          }}
        >
          Simulate Seamlessly
        </motion.div>
      ) : null}
      {/* {index === 2 ? (
        <motion.div
          key={2}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 1.4,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
          }}
        >
          <OutlinedText>Smart Future Planning</OutlinedText>
        </motion.div>
      ) : null} */}

      {index === 2
        ? text.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.45,
                delay: i / 2,
              }}
              key={i}
            >
              {el}{" "}
            </motion.span>
          ))
        : null}
    </Typography>
  );
}

export default BannerText;
