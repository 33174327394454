import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/InputField/CustomInput";
import PrimaryBtn from "../../components/button/PrimaryBtn";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import images from "../../constant/images";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { redirectToPath } from "../../helper/com-helper";
import { toast } from "react-toastify";
import validator from "validator";
import {
  generateUrlSafeString,
  getPath,
  processLogin,
  sha256Base64UrlEncoded,
} from "../../helper/commonHelper";
import {
  authorizeOkta,
  handleError,
  HOST,
  loginUser,
  recaptchaSiteKey,
  validateOKTALogin,
} from "../../utils/ApiHandler";
import ProcessingLoader from "../../components/ProcessingLoader";
import useRecaptchaV3 from "../../hooks/recaptchaV3";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ValidityModal = ({ message, onClose }) => (
  <Box className="modal-overlay">
    <Box className="modal-content">
      <Typography className="modal-message">{message}</Typography>
      <Box className="modal-close-button" onClick={onClose}>
        Close
      </Box>
    </Box>
  </Box>
);

function LoginForm() {
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showValidityModal, setShowValidityModal] = useState(false);
  const [validityMessage, setValidityMessage] = useState("");
  const [path, setPath] = useState(window.location.href);
  const user = JSON.parse(localStorage.getItem("user") || null);
  const { t } = useTranslation();
  const executeRecaptcha = useRecaptchaV3(recaptchaSiteKey);

  useEffect(() => {
    if (!isEmpty(user)) {
      const permissions = get(user, "permissions", []);
      const roles = get(user, "roles", []);
      const path = getPath(permissions, roles);
      redirectToPath(path);
    }
  }, [user]);

  useEffect(() => {
    if (path.includes("login")) {
      const str = path.slice(path.indexOf("=") + 1, path.indexOf("&"));
      if (str) {
        setLoader(true);
        window.history.pushState({}, null, `${HOST}/scai`);

        (async () => {
          const res = await validateOKTALogin(
            str,
            localStorage.getItem("verifier")
          );
          const selected_business_unit = JSON.parse(
            localStorage.getItem("selected_business_unit") || null
          );
          processLogin(res, setLoader, selected_business_unit);
        })();
      }
    }
  }, [path]);

  const [formData, SetFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [emailError, setEmailError] = useState(false);

  const { email, password, showPassword } = formData;

  const handleClickShowPassword = () => {
    SetFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      setLoader(true);
      submitUser(email, password);
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      setEmailError(false);
    }
    SetFormData({
      ...formData,
      [prop]:
        prop === "email"
          ? event.target.value?.toLowerCase()
          : event.target.value,
    });
  };

  //To check licensing validity through response header
  const checkLicensingValidity = (response) => {
    const validityThreshold = response?.headers?.["alert-when-less-than"];
    const licenseValidity = response?.headers?.["validity"];

    const isAcknowledged = sessionStorage.getItem("licenseAcknowledged");

    if (!validityThreshold || !licenseValidity || isAcknowledged) {
      return true;
    }

    // Check if validity is less than the threshold
    if (Number(licenseValidity) <= Number(validityThreshold)) {
      setValidityMessage(
        `Your subscription is about to expire in ${licenseValidity} days.
          Please renew it for uninterrupted service.`
      );
      setShowValidityModal(true);
      return false;
    }
    return true;
  };

  const submitUser = async (email, password) => {
    const isEmailValid = validator.isEmail(email);
    if (!isEmailValid) {
      setEmailError(true);
      return;
    }
    try {
      setLoader(true);
      if (email === "" || password === "") {
        toast.error("Enter Email ID / Password !!!");
        setLoader(false);
      } else {
        // Generate reCAPTCHA token
        const recaptchaToken = await executeRecaptcha("login");

        if (!recaptchaToken) {
          toast.error("Failed to verify reCAPTCHA. Please try again.");
          setLoader(false);
          return;
        }
        const res = await loginUser(email, password, recaptchaToken);
        const isLicenseValid = checkLicensingValidity(res);
        if (!isLicenseValid) {
          setLoader(false);
          return;
        }
        const selected_business_unit = JSON.parse(
          localStorage.getItem("selected_business_unit") || null
        );
        processLogin(res, setLoader, selected_business_unit);
      }
    } catch (error) {
      setLoader(false);
      let error_obj = error?.message ? JSON.parse(error?.message) : {};
      if (error_obj.error === "mfa_required") {
        if (error_obj.mfa_token !== "") {
          navigate("/scai/login-mfa", {
            state: {
              oob_code: error_obj.oob_code,
              mfa_token: error_obj.mfa_token,
              email: error_obj.email,
            },
          });
        }
      }
    }
  };

  const onOKTAButtonClick = () => {
    const generatedString = generateUrlSafeString();
    localStorage.setItem("authenticator", "okta");
    localStorage.setItem("verifier", generatedString);
    sha256Base64UrlEncoded(generatedString)
      .then((encodedHash) => {
        localStorage.setItem("challenge", encodedHash);
        authorizeOkta(encodedHash);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleCloseValidityModal = () => {
    setShowValidityModal(false);
    sessionStorage.setItem("licenseAcknowledged", "true");
    submitUser(email, password);
  };

  return (
    <>
      {loader && <ProcessingLoader />}
      <Box mt={"4rem"} mb={"3rem"}>
        <Grid
          container
          gap={2}
          sx={{
            padding: "2rem 2.5rem",
            borderRadius: "1.5rem",
            boxShadow: (theme) => theme.palette.shadow.login,
            alignItems: "baseline",
          }}
        >
          <Grid item xs={3.4}>
            <CustomInput
              placeholder="Enter mail address..."
              type="text"
              value={email}
              onChange={handleChange("email")}
              sx={
                emailError
                  ? {
                      color: (theme) => theme.palette.custom.errorColor,
                    }
                  : {}
              }
            />
            {emailError && (
              <Typography
                variant="body2"
                mt="1rem"
                align="left"
                sx={{
                  color: (theme) => theme.palette.custom.errorColor,
                  fontSize: "1rem",
                  fontWeight: 300,
                }}
              >
                Please enter valid email address
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <CustomInput
                placeholder="Your Password"
                type={showPassword ? "text" : "password"}
                name="password"
                onKeyDown={(e) => {
                  handleKeypress(e);
                }}
                value={password}
                onChange={handleChange("password")}
              />
              <IconButton
                disableRipple
                sx={{
                  borderRadius: 0,
                  paddingBottom: ".3rem",
                  borderBottom: (theme) => `.1rem solid ${theme.palette.custom.inputBorder}`,
                }}
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Stack>
            <Typography
              variant="body2"
              mt="1rem"
              align="right"
              sx={{
                color: (theme) => theme.palette.custom.forgotPasswordColor,
                fontSize: "1rem",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <Link to={"/scai/forgot-password"}>Forgot password?</Link>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              gap={1}
            >
              <PrimaryBtn
                disableRipple
                size="small"
                sx={{
                  fontFamily: (theme) =>
                    theme.palette.customFontFamily.secondary,
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  letterSpacing: ".1rem",
                }}
                endIcon={<ArrowOutwardIcon />}
                disabled={emailError || !password}
                onClick={() => {
                  submitUser(email, password);
                }}
              >
                Login here
              </PrimaryBtn>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                  }}
                >
                  Or Via
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: "auto !important",
                    opacity: 1,
                  }}
                />
                <IconButton>
                  <Box
                    sx={{
                      width: "5rem",
                      cursor: "pointer",
                    }}
                    component="img"
                    src={images.okta}
                    alt="okta"
                    onClick={() => {
                      setLoader(true);
                      onOKTAButtonClick();
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        {showValidityModal && (
          <ValidityModal
            message={validityMessage}
            onClose={handleCloseValidityModal}
          />
        )}
      </Box>
    </>
  );
}

export default LoginForm;
