import { createBrowserHistory } from "history";
import React from "react";
import SCAILayout from "../../../components/SCAILayout";
import MicroFrontend from "../../../MicroFrontend";
import "../../style.scss";
const defaultHistory = createBrowserHistory();

const SnopFrontend = () => {
  const { REACT_APP_SNOP_HOST: snopHost } = process.env;
  
  function SnopModule({ history }) {
    return <MicroFrontend history={history} host={snopHost} name="snop" />;
  }


  return (
    <SCAILayout>
      <div id="snop">
        <div>
          <SnopModule history={defaultHistory} />
        </div>
      </div>
    </SCAILayout>
  );
};

export default SnopFrontend;
