import { Box } from "@mui/material";
import React, { useRef } from "react";
import MainBanner from "./MainBanner";
import AboutLanding from "../../components/AboutLanding";
import FeatureLanding from "../../components/FeatureLanding";
import FeatureLogo from "../../components/FeatureLanding/FeatureLogo";
import FeatureCard from "../../components/FeatureLanding/FeatureCard";
import FeatureCardSeamless from "../../components/FeatureLanding/FeatureCardSeamless";
import HowItWork from "../../components/HowItWork";
import Discover from "../../components/HowItWork/Discover";
import GetInTouch from "../../components/GetInTouch";
import ForwardLooking from "../../components/AboutLanding/ForwardLooking";
import { useScroll } from "framer-motion";
import { StyleSheet } from "./ScrollComponent";
import ScrollContainer from "./ScrollComponent";
import Header from "../../components/header";
import { isDemoAvl } from "../../utils/ApiHandler";
// import Parallax from "../../components/Sticky/BottomSticky";

function LandingLogin() {
  const forwardLookingRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: forwardLookingRef,
    offset: ["end end", "start start"],
  });

  return (
    <>
      {/* <Parallax/> */}
      <ScrollContainer id={0}>
        <Box>
          <Box>
            <Header />
            <MainBanner />
            <AboutLanding />
          </Box>
        </Box>
      </ScrollContainer>
      <ScrollContainer id={1}>
        <Box
          ref={forwardLookingRef}
          sx={{
            minHeight: "200vh",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
              }}
            >
              <ForwardLooking
                isProgress={true}
                scrollYProgress={scrollYProgress}
              />
            </Box>
          </Box>
        </Box>
      </ScrollContainer>
      <ScrollContainer id={2}>
        <Box bgcolor="common.black" id="features" sx={{ mt: "1rem" }}>
          <Box
            sx={{
              zIndex: 1,
              position: "sticky",
              top: 0,
            }}
          >
            <FeatureLanding />
          </Box>

          <FeatureLogo />
          <Box
            sx={{
              position: "sticky",
              top: 150,
            }}
          >
            <FeatureCard />
          </Box>

          <Box
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <FeatureCardSeamless />
          </Box>
        </Box>
      </ScrollContainer>
      <ScrollContainer id={3}>
        <HowItWork />
        <Discover />
      </ScrollContainer>
      {isDemoAvl && (
        <ScrollContainer id={4}>
          <GetInTouch />
        </ScrollContainer>
      )}
      <StyleSheet />
    </>
  );
}

export default LandingLogin;
