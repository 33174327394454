// recaptchaV3.js
import { useCallback, useEffect, useState } from 'react';

const useRecaptchaV3 = (siteKey) => {
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

  useEffect(() => {
    const initializeRecaptcha = () => {
      if (window.grecaptcha && window.grecaptcha.execute) {
        setIsRecaptchaReady(true);
        // Hide the reCAPTCHA badge (branding)
        const recaptchaBadge = document.querySelector('.grecaptcha-badge');
        if (recaptchaBadge) {
          recaptchaBadge.style.visibility = 'hidden';
        }
      } else {
        setTimeout(initializeRecaptcha, 100);
      }
    };
    if (!window.grecaptcha) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = initializeRecaptcha;
    } else {
      initializeRecaptcha();
    }
    return () => {
      const script = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${siteKey}"]`);
      if (script) script.remove();
    };
  }, [siteKey]);

  const executeRecaptcha = useCallback(
    async (action) => {
      if (isRecaptchaReady && window.grecaptcha && window.grecaptcha.execute) {
        try {
          return await window.grecaptcha.execute(siteKey, { action });
        } catch (error) {
          return null;
        }
      }
      return null;
    },
    [isRecaptchaReady, siteKey]
  );

  return executeRecaptcha;
};

export default useRecaptchaV3;
