import { Config, Inventory, Order, Production, Raw, Reports, Supply } from "../../../../assets/svg-icons";
export const SNP = {
  name: "Supply Planning",
  route: null,
  shortName: "Supply",
  icon: Supply,
  permissions: [],
  child: {
    Inventory: {
      name: "Inventory",
      route: null,
      permissions: [],
      icon: Inventory,
      child: {
        "Inventory Management": {
          name: "Inventory Management",
          permissions: [
            "INVENTORY_MGMT_STATISTICS_VIEW",
            "INVENTORY_MGMT_PREPROCESS_VIEW",
            "INVENTORY_MGMT_JOB_SCH_VIEW",
            "INVENTORY_MGMT_DASHBOARD_VIEW",
            "INVENTORY_MGMT_JOB_SCH_CREATE",
            "INVENTORY_MGMT_JOB_HISTORY_VIEW",
            "INVENTORY_MGMT_JOB_SCH_UPDATE",
            "INVENTORY_MGMT_CONFIGURATION_VIEW",
            "INVENTORY_MGMT_CONFIGURATION_CREATE",
            "INVENTORY_MGMT_CONFIGURATION_UPDATE",
            "INVENTORY_MGMT_NETWORK_VIS_VIEW",
          ],
          route: "/snp/inventory/management",
        },
        "Replenishment Plan": {
          name: "Replenishment Plan",
          permissions: [
            "REPLENISHMENT_PLAN_PREPROCESS_VIEW",
            "REPLENISHMENT_PLAN_JOB_SCH_VIEW",
            "REPLENISHMENT_PLAN_JOB_SCH_CREATE",
            "REPLENISHMENT_PLAN_JOB_HISTORY_VIEW",
            "REPLENISHMENT_PLAN_JOB_SCH_UPDATE",
            "REPLENISHMENT_PLAN_CONFIGURATION_VIEW",
            "REPLENISHMENT_PLAN_CONFIGURATION_CREATE",
            "REPLENISHMENT_PLAN_CONFIGURATION_UPDATE",
            "REPLENISHMENT_PLAN_NETWORK_VIS_VIEW",
            "REPLENISHMENT_PLAN_VIEW",
          ],
          route: "/snp/inventory/replenishment-plan",
        },
      },
    },
    Production: {
      name: "Production",
      route: null,
      permissions: [],
      icon: Production,
      child: {
        "Production Requirement": {
          name: "Production Requirement",
          route: "/snp/production/requirement",
          permissions: [
            "PRODUCTION_RQMT_PREPROCESS_VIEW",
            "PRODUCTION_RQMT_JOB_SCH_VIEW",
            "PRODUCTION_RQMT_DASHBOARD_VIEW",
            "PRODUCTION_RQMT_JOB_SCH_CREATE",
            "PRODUCTION_RQMT_JOB_HISTORY_VIEW",
            "PRODUCTION_RQMT_JOB_SCH_UPDATE",
            "PRODUCTION_RQMT_CONFIGURATION_VIEW",
            "PRODUCTION_RQMT_CONFIGURATION_CREATE",
            "PRODUCTION_RQMT_CONFIGURATION_UPDATE",
            "PRODUCTION_RQMT_NETWORK_VIS_VIEW",
          ],
        },
        "Production Plan": {
          name: "Production Plan",
          route: "/snp/production/production-plan",
          permissions: [
            "PRODUCTION_PLAN_PREPROCESS_VIEW",
            "PRODUCTION_PLAN_JOB_SCH_VIEW",
            "PRODUCTION_PLAN_JOB_SCH_CREATE",
            "PRODUCTION_PLAN_JOB_HISTORY_VIEW",
            "PRODUCTION_PLAN_JOB_SCH_UPDATE",
            "PRODUCTION_PLAN_CONFIGURATION_VIEW",
            "PRODUCTION_PLAN_CONFIGURATION_CREATE",
            "PRODUCTION_PLAN_CONFIGURATION_UPDATE",
            "PRODUCTION_PLAN_NETWORK_VIS_VIEW",
            "PRODUCTION_PLAN_VIEW",
            "PRODUCTION_PLAN_APP_GROUP_VIEW",
            "PRODUCTION_PLAN_APP_ORG_VIEW",
          ],
        },
        "Production Scheduling": {
          name: "Production Scheduling",
          route: "/snp/production/production-scheduling",
          permissions: ["PRODUCTION_SCHEDULING"]
        }
      },
    },
    "Raw Material": {
      name: "Raw Material",
      route: null,
      permissions: [],
      icon: Raw,
      child: {
        "RM Management": {
          name: "RM Management",
          route: "/snp/raw-material/requirement",
          permissions: ["RM_RQMT_DASHBOARD_VIEW"],
        },
        "RM Plan": {
          name: "RM Plan",
          route: "/snp/raw-material/plan",
          permissions: [
            "RM_PLAN_VIEW",
            "RM_PLAN_PREPROCESS_VIEW",
            "RM_PLAN_JOB_SCH_VIEW",
            "RM_PLAN_JOB_SCH_CREATE",
            "RM_PLAN_JOB_SCH_UPDATE",
            "RM_PLAN_CONFIGURATION_VIEW",
            "RM_PLAN_CONFIGURATION_CREATE",
            "RM_PLAN_CONFIGURATION_UPDATE",
            "RM_PLAN_NETWORK_VIS_VIEW",
          ],
        },
      },
    },
    Reports: {
      name: "Reports",
      route: "/snp/reports",
      icon: Reports,
      permissions: ["SUPPLY_PLAN_REPORTS_VIEW"],
    },
    "Orders Management": {
      name: "Orders Management",
      route: "/snp/orders-management",
      icon: Order,
      permissions: ["SUPPLY_PLAN_ORDERS_MANAGEMENT_VIEW"],
    },
    Configurations: {
      name: "Configurations",
      route: "/snp/configurations",
      icon: Config,
      permissions: ["SUPPLY_PLAN_CONFIGURATIONS_VIEW"],
    },
  },
  extraRoutes: [
    {
      route: "/snp/inventory/management/inventory-stats",
      permissions: ["INVENTORY_MGMT_STATISTICS_VIEW"],
    },
    {
      route: "/snp/inventory/management/preprocesses",
      permissions: ["INVENTORY_MGMT_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/inventory/management/preprocesses/process/:type",
      permissions: ["INVENTORY_MGMT_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/inventory/management/job-scheduler",
      permissions: ["INVENTORY_MGMT_JOB_SCH_VIEW"],
    },
    {
      route: "/snp/inventory/management/job-scheduler-create",
      permissions: ["INVENTORY_MGMT_JOB_SCH_CREATE"],
    },
    {
      route: "/snp/inventory/management/job-scheduler-history/:id",
      permissions: ["INVENTORY_MGMT_JOB_HISTORY_VIEW"],
    },
    {
      route: "/snp/inventory/management/job-scheduler-edit/:id",
      permissions: ["INVENTORY_MGMT_JOB_SCH_UPDATE"],
    },
    {
      route: "/snp/inventory/management/config",
      permissions: ["INVENTORY_MGMT_CONFIGURATION_VIEW"],
    },
    {
      route: "/snp/inventory/management/config-create",
      permissions: ["INVENTORY_MGMT_CONFIGURATION_CREATE"],
    },
    {
      route: "/snp/inventory/management/config-edit/:id",
      permissions: ["INVENTORY_MGMT_CONFIGURATION_UPDATE"],
    },
    {
      route: "/snp/inventory/management/network",
      permissions: ["INVENTORY_MGMT_NETWORK_VIS_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/org-plan/:planId",
      permissions: ["REPLENISHMENT_PLAN_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/group-plan/:planId",
      permissions: ["REPLENISHMENT_PLAN_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/preprocesses",
      permissions: ["REPLENISHMENT_PLAN_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/preprocesses/process/:type",
      permissions: ["REPLENISHMENT_PLAN_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/job-scheduler",
      permissions: ["REPLENISHMENT_PLAN_JOB_SCH_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/job-scheduler-create",
      permissions: ["REPLENISHMENT_PLAN_JOB_SCH_CREATE"],
    },
    {
      route: "/snp/inventory/replenishment-plan/job-scheduler-history/:id",
      permissions: ["REPLENISHMENT_PLAN_JOB_HISTORY_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/job-scheduler-edit/:id",
      permissions: ["REPLENISHMENT_PLAN_JOB_SCH_UPDATE"],
    },
    {
      route: "/snp/inventory/replenishment-plan/config",
      permissions: ["REPLENISHMENT_PLAN_CONFIGURATION_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/config-create",
      permissions: ["REPLENISHMENT_PLAN_CONFIGURATION_CREATE"],
    },
    {
      route: "/snp/inventory/replenishment-plan/config-edit/:id",
      permissions: ["REPLENISHMENT_PLAN_CONFIGURATION_UPDATE"],
    },
    {
      route: "/snp/inventory/replenishment-plan/network",
      permissions: ["REPLENISHMENT_PLAN_NETWORK_VIS_VIEW"],
    },
    {
      route: "/snp/production/requirement/preprocesses",
      permissions: ["PRODUCTION_RQMT_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/production/requirement/job-scheduler",
      permissions: ["PRODUCTION_RQMT_JOB_SCH_VIEW"],
    },
    {
      route: "/snp/production/requirement/job-scheduler-create",
      permissions: ["PRODUCTION_RQMT_JOB_SCH_CREATE"],
    },
    {
      route: "/snp/production/requirement/job-scheduler-history:/id",
      permissions: ["PRODUCTION_RQMT_JOB_HISTORY_VIEW"],
    },
    {
      route: "/snp/production/requirement/job-scheduler-edit/:id",
      permissions: ["PRODUCTION_RQMT_JOB_SCH_UPDATE"],
    },
    {
      route: "/snp/production/requirement/config",
      permissions: ["PRODUCTION_RQMT_CONFIGURATION_VIEW"],
    },
    {
      route: "/snp/production/requirement/config-create",
      permissions: ["PRODUCTION_RQMT_CONFIGURATION_CREATE"],
    },
    {
      route: "/snp/production/requirement/config-edit/:id",
      permissions: ["PRODUCTION_RQMT_CONFIGURATION_UPDATE"],
    },
    {
      route: "/snp/production/requirement/network",
      permissions: ["PRODUCTION_RQMT_NETWORK_VIS_VIEW"],
    },
    {
      route: "/snp/production/production-plan/app-group-level-view/:id",
      permissions: ["PRODUCTION_PLAN_APP_GROUP_VIEW"],
    },
    {
      route: "/snp/production/production-plan/app-org-level-view/:id",
      permissions: ["PRODUCTION_PLAN_APP_ORG_VIEW"],
    },
    {
      route: "/snp/production/production-plan/app-group-level-view/:id/add-pwo",
      permissions: ["PRODUCTION_PLAN_PWO_CREATE"],
    },
    {
      route: "/snp/raw-material/requirement/preprocesses",
      permissions: ["RM_RQMT_PREPROCESS_VIEW"],
    },
    {
      route: "/snp/raw-material/requirement/job-scheduler",
      permissions: ["RM_RQMT_JOB_SCH_VIEW"],
    },
    {
      route: "/snp/raw-material/requirement/job-scheduler-create",
      permissions: ["RM_RQMT_JOB_SCH_CREATE"],
    },
    {
      route: "/snp/raw-material/requirement/job-scheduler-edit/:id",
      permissions: ["RM_RQMT_JOB_SCH_UPDATE"],
    },
    {
      route: "/snp/raw-material/requirement/config",
      permissions: ["RM_RQMT_CONFIGURATION_VIEW"],
    },
    {
      route: "/snp/raw-material/requirement/config-create",
      permissions: ["RM_RQMT_CONFIGURATION_CREATE"],
    },
    {
      route: "/snp/raw-material/requirement/config-edit/:id",
      permissions: ["RM_RQMT_CONFIGURATION_UPDATE"],
    },
    {
      route: "/snp/raw-material/requirement/network",
      permissions: ["RM_RQMT_NETWORK_VIS_VIEW"],
    },
    {
      route: "/snp/reports/:id",
      permissions: ["SUPPLY_PLAN_REPORTS_VIEW"],
    },
    {
      route: "/snp/reports/:id",
      permissions: ["SUPPLY_PLAN_REPORTS_VIEW"],
    },
    {
      route: "/snp/inventory/replenishment-plan/custom-view/create",
      permissions: ["REPLENISHMENT_PLAN_CUSTOM_VIEW_CREATE"],
    },
    {
      route: "/snp/inventory/replenishment-plan/custom-view/edit/:id",
      permissions: ["REPLENISHMENT_PLAN_CUSTOM_VIEW_EDIT"],
    },
    {
      route: "/snp/production/production-plan/custom-view/create",
      permissions: ["PRODUCTION_PLAN_APP_CUSTOM_VIEW_CREATE"],
    },
    {
      route: "/snp/production/production-plan/custom-view/edit/:id",
      permissions: ["PRODUCTION_PLAN_APP_CUSTOM_VIEW_EDIT"],
    },
    {
      route: "/snp/raw-material/plan/custom-view/create",
      permissions: ["RM_PLAN_CUSTOM_VIEW_CREATE"],
    },
    {
      route: "/snp/raw-material/plan/custom-view/edit/:id",
      permissions: ["RM_PLAN_CUSTOM_VIEW_EDIT"],
    },
  ],
};
