import { Box, Typography } from "@mui/material";
import React from "react";
import Monitor from "./Monitor";
import { monitorData } from "../../constant/constant";

function Discover() {
  return (
    <Box
      id="works"
      sx={{
        maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
        margin: "0px auto",
        p: "5rem 1rem",
      }}
    >
      <Box mb={8}>
        <Typography
          sx={{
            fontSize: "1.4rem",
            color: (theme) => theme.palette.custom.cardPara1Color,
            fontFamily: (theme) => theme.palette.customFontFamily.secondary,
          }}
        >
          More to discover
        </Typography>
        <Box mt={1} mb={3}>
          <Typography variant="h4">Unlock New Possibilities</Typography>
          <Typography variant="h4">in Supply Chain</Typography>
          <Typography variant="h4">Management with</Typography>
          <Typography variant="h4">Cognition</Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: (theme) => theme.palette.secondary.light,
            fontFamily: (theme) => theme.palette.customFontFamily.secondary,
            maxWidth: "71rem",
          }}
        >
          We reimagined the future of supply chain management from the ground
          up. Instead of following the path of traditional ERP systems, we
          created a cognition-driven platform designed to adapt, predict, and
          grow with your business. Infused with AI insights, real-time
          analytics, and predictive modeling, it delivers exceptional efficiency
          and empowers you to stay ahead of every challenge. This is not just a
          tool—it’s the evolution of supply chain excellence.
        </Typography>
      </Box>
      <Box>
        <Monitor data={monitorData} />
      </Box>
    </Box>
  );
}

export default Discover;
