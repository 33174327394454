import { Button, styled } from "@mui/material";
import React from "react";

const WrapperBtn = styled(Button)(({ theme }) => ({
  borderRadius: "4rem",
  padding: "10px 20px",
  fontSize: "1.2rem",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:disabled": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.disabled,
    cursor: "not-allowed",
  },
}));

function PrimaryBtn({ children, ...otherProps }) {
  return <WrapperBtn {...otherProps}>{children}</WrapperBtn>;
}

export default PrimaryBtn;
