import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Tooltip, useTheme } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import { default as React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ArrowDown, ArrowUp } from '../../../assets/svg-icons';
import { activateSNOPMessage, APPBAR_MENU, ENABLE_FOR_MENU } from '../../../constant/constant';
import { accessibleMenus, getActiveMenu, redirectToPath } from '../../../helper/com-helper';
import { getLocalStorageItem } from '../../../helper/localStorageHelper';
import MenuPopOver from '../menuPopOver';

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.textColor, // Default icon color
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme, open }) => ({
    padding: 0,
    position: 'relative',
    '&:hover': {
        '& .MuiTypography-root': {
            color: theme.palette.primary.activeText,
        },
    },
}));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        //color: theme.palette.common.black,
        color: theme.palette.primary.tooltipBackground,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.tooltipBackground,
        border: `0.1rem solid ${theme.palette.primary.tooltipBorder}`,
        fontSize: "0.8rem"
    },
}));

const MenuItems = ({ menuData, open, isChild = false, isPopOver = false, selectedMenu }) => {
    const theme = useTheme();
    const location = useLocation();
    const [expandedMenus, setExpandedMenus] = useState(isPopOver && !isChild ? { [menuData[0].name]: true } : {});
    const snop = getLocalStorageItem("snop") ?? {};

    const [popOverData, setPopOverData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (!open)
            setExpandedMenus({});
    }, [open])

    const toggleMenu = (menu) => {
        if (menu?.child) {
            setExpandedMenus((prev) => ({
                ...prev,
                [menu?.name]: !prev[menu?.name],
            }));
        } else {
            redirectToPath(menu);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopOver = (e, menu) => {
        if (!!menu?.child) {
            setAnchorEl(e.currentTarget);
            setPopOverData([menu])
        } else {
            redirectToPath(menu)
        }
    }

    const onButtonClick = (e, item, isMenuDisabled) => {
        if (isMenuDisabled) {
            toast.error(activateSNOPMessage);
            return;
        }
        if (!open) {
            openPopOver(e, item)
        } else {
            toggleMenu(item)
        }
    }

    return (
        <List sx={{
            mb: isPopOver ? "0.5rem" : "0rem",
            py: isChild || isPopOver ? "0rem" : "0.8rem",
        }}>
            {menuData?.map((item, index) => {
                const isExpanded = expandedMenus[item?.name] || false;
                const MenuIcon = item?.icon;
                const isMenuActive = getActiveMenu({ item, path: location.pathname });
                const isMenuDisabled = !ENABLE_FOR_MENU.includes(item?.name) && isEmpty(snop) && selectedMenu?.parent === APPBAR_MENU?.IBP;
                return (
                    <>
                        <ListItem
                            key={`${item?.name}${index}`}
                            disablePadding
                            position="relative"
                            sx={{
                                display: "block",
                                "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    left: 0,
                                    top: "50%",
                                    transform: `translateY(${open ? "-50%" : "-70%"})`,
                                    height: open ? "60%" : "40%", // Adjust heights for open and closed states
                                    width: "0.15rem", // Border thickness
                                    borderTopRightRadius: "0.3rem",
                                    borderBottomRightRadius: "0.3rem",
                                    backgroundColor: isMenuActive && !isExpanded && item?.icon
                                        ? theme.palette.primary.activeBorderColor
                                        : "transparent",
                                },
                            }}
                        >
                            <ListItemButtonStyled
                                onClick={(e) => onButtonClick(e, item, isMenuDisabled)}
                                sx={{
                                    opacity: isMenuDisabled ? 0.5 : 1,
                                    minHeight: isChild ? "2.8rem" : "3.5rem",
                                    justifyContent: open ? "initial" : "center",
                                    pr: "1.4rem",
                                    ml: !item?.icon && isChild && "2rem",
                                    mr: !item?.icon && isChild ? "1.8rem" : "0rem",
                                    pl: isChild ? item?.icon ? "1.8rem" : "1.4rem" : "1rem",
                                    borderRight: isChild && !item?.icon ? `0.1rem solid ${theme.palette.primary.borderColor}` : "none",
                                    "&::before": {
                                        content: '""',
                                        position: "absolute",
                                        left: 0,
                                        top: "50%",
                                        transform: `translateY(${open ? "-50%" : "-70%"})`,
                                        height: open ? "60%" : "40%", // Adjust heights for open and closed states
                                        width: "0.15rem", // Border thickness
                                        borderTopRightRadius: "0.3rem",
                                        borderBottomRightRadius: "0.3rem",
                                        backgroundColor: isMenuActive && (!item?.child || !isExpanded)
                                            ? theme.palette.primary.activeBorderColor
                                            : "transparent",
                                    },
                                }}
                            >
                                <Stack
                                    direction={open ? "row" : "column"}
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                    sx={{
                                        my: open ? "none" : "0.8rem",
                                        textAlign: "center"
                                    }}
                                >
                                    <CustomTooltip
                                        title={open ? "" : item?.name}
                                        arrow
                                        placement="right"
                                    >
                                        {item?.icon && (
                                            <ListItemIconStyled
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? "1rem" : "none",
                                                    justifyContent: "center",
                                                    color: isExpanded
                                                        ? theme.palette.primary.activeText
                                                        : theme.palette.primary.textColor, // Default icon color
                                                }}
                                            >
                                                <MenuIcon color={theme.palette.primary.textColor} />
                                            </ListItemIconStyled>
                                        )}
                                        {!open && (
                                            <ListItemText
                                                primary={item?.shortName}
                                                sx={{
                                                    transition: "color 0.3s ease",
                                                    mt: "0rem"
                                                }}
                                                primaryTypographyProps={{
                                                    fontSize: open ? "1.1rem" : "0.7rem", // Set your desired font size
                                                    color: isMenuActive && (!item?.child || !isExpanded)
                                                        ? theme.palette.primary.activeText
                                                        : theme.palette.primary.textColor, // Optional: Add color here
                                                }}
                                            />
                                        )}
                                    </CustomTooltip>
                                </Stack>

                                <ListItemText
                                    primary={item?.name}
                                    sx={{
                                        transition: "color 0.3s ease",
                                        opacity: open ? 1 : 0,
                                    }}
                                    primaryTypographyProps={{
                                        fontSize: "1.1rem", // Set your desired font size
                                        color: isMenuActive
                                            ? theme.palette.primary.activeText
                                            : theme.palette.primary.textColor, // Optional: Add color here
                                    }}
                                />
                                {item?.child && open && (
                                    <ListItemIconStyled
                                        sx={{
                                            minWidth: 0,
                                            justifyContent: "center",
                                            color: isExpanded
                                                ? theme.palette.primary.activeText
                                                : theme.palette.primary.textColor, // Default icon color
                                        }}
                                    >
                                        {isExpanded ? (
                                            <>
                                                <ArrowUp color={theme.palette.primary.textColor} />
                                            </>

                                        ) : (
                                            <ArrowDown color={theme.palette.primary.textColor} />
                                        )}
                                    </ListItemIconStyled>
                                )}
                            </ListItemButtonStyled>
                        </ListItem>
                        {!!item?.child && (
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <MenuItems
                                    menuData={accessibleMenus(Object?.values(item?.child))}
                                    open={open}
                                    isChild={true}
                                    isPopOver={isPopOver}
                                />
                            </Collapse>
                        )
                        }
                    </>
                );
            })}
            {/* Popover */}
            {
                !open && (
                    <MenuPopOver
                        handleClose={handleClose}
                        menuData={popOverData}
                        anchorEl={anchorEl}
                    />
                )
            }
        </List >
    );
}

export default MenuItems