import { Box, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  motion,
  AnimatePresence,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";
import Fade from "@mui/material/Fade";

const Accordion = styled((props) => <MuiAccordion disableGutters {...props} />)(
  ({ theme }) => ({
    boxShadow: "none",
    border: "none",
    borderBottom: `.1rem solid ${theme.palette.divider}`,
    "&::before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  () => ({
    padding: 0,
    border: 0,
    margin: ".8rem 0",

    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
      {
        transform: "rotate(90deg)",
        border: 0,
      },
    [`& .${accordionSummaryClasses.content}`]: {
      margin: "1rem 0",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 0 1rem 0",
}));

function CustomAccordion({ scrollYProgress, data, isProgress }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const expandedS = useTransform(scrollYProgress, [1, 0], [0, data.length]);

  useMotionValueEvent(expandedS, "change", (latest) => {
    setTimeout(() => {
      setExpandedIndex(Math.floor(latest));
    }, 500);
  });

  return (
    <>
      {data.map((item, idx) => {
        const isLastItem = idx === data.length - 1;
        return (
          <Box key={idx}>
            <Accordion
              expanded={isProgress && expandedIndex === idx}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                borderBottom: isLastItem ? "none" : undefined,
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  ...(expandedIndex === idx
                    ? {
                        "& .Mui-expanded": {
                          mb: "0",
                        },
                      }
                    : {}),
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "3rem",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AnimatePresence>
                {isProgress && expandedIndex === idx && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      duration: 0.5,
                      ease: [0.25, 0.8, 0.25, 1],
                    }}
                  >
                    <AccordionDetails
                      sx={{
                        ...(expandedIndex === idx
                          ? {
                              pb: "1.6rem",
                            }
                          : {}),
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "left",
                          fontFamily: (theme) =>
                            theme.palette.customFontFamily.secondary,
                        }}
                      >
                        {item.content}
                      </Typography>
                    </AccordionDetails>
                  </motion.div>
                )}
              </AnimatePresence>
            </Accordion>
          </Box>
        );
      })}
    </>
  );
}

export default CustomAccordion;
