import { Demand } from "../../../../assets/svg-icons";

export const PAI = {
  name: "Product Planning",
  route: null,
  permissions: [],
  icon: Demand,
  shortName: "Product",
  child: {
    Forecasting: {
      name: "Forecasting",
      route: "/pp/forecast",
      permissions: ["MARKETING_FORECAST_VIEW"],
    },
  },
};
