import {
  Badge,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  Switch,
  Tooltip,
  useTheme
} from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import Cookies from "js-cookie";
import { get } from 'lodash';
import React, { useState } from 'react';
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { Account, ArrowDown, ArrowUp, BU, Signout } from "../../assets/svg-icons";
import {
  firstName,
  lastName,
  selected_business_unit
} from "../../config/localStorage";
import {
  processNodeAndPermissions,
  redirectToPath,
} from "../../helper/com-helper";
import {
  createBuOptions,
  getPath,
  isAdminUser,
  setUserCookies,
  setUserLocalStorage
} from "../../helper/commonHelper";
import colorVariables from "../../style/sass/_colors.scss";
import { getActiveSnop, getConfiguration, getSnopCalender, logout } from "../../utils/ApiHandler";
import { defaultTheme } from "../../utils/const";
import { ROUTES_PATH } from "../../utils/EndPoints";
import { SNOP } from "../../utils/RouteHelper/IBP/SNOP";
import ProcessingLoader from "../ProcessingLoader";
import { useThemeToggle } from "../SCAILayout";

const SCAIPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    backgroundColor: theme.palette.primary.background,
    color: colorVariables.greyColor3,
    borderRadius: "0.8rem",
    border: `0.15rem solid ${theme.palette.primary.borderColor}`
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700', // Example: green badge
    color: '#44b700',
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.background}`, // Badge outline
    width: '0.3rem', // Reduced width
    height: '0.7rem', // Reduced height
    borderRadius: '50%', // Keeps badge circular
    top: '0.1rem', // Adjusted top position for smaller badge
    right: '0.1rem', // Adjusted right position for smaller badge
  },
}));

const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#2C2C2F',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    //color: theme.palette.common.black,
    color: theme.palette.primary.tooltipBackground,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.tooltipBackground,
    border: `0.1rem solid ${theme.palette.primary.tooltipBorder}`,
    fontSize: "0.8rem"
  },
}));

const MultiAvatar = () => {
  const theme = useTheme();
  const themeMode = JSON.parse(localStorage.getItem("settings"))?.platform?.theme || defaultTheme;
  const { toggleMode } = useThemeToggle() || {};
  let user_metadata = JSON.parse(localStorage.getItem("user") || null);
  const selected_bu = JSON.parse(localStorage.getItem("selected_business_unit") || null);
  let business_units = get(user_metadata, "business_units") || [];
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const buOption = createBuOptions(business_units);
  const isAdmin = isAdminUser();

  const [buExpand, setBuExpand] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleBuOption = async (option) => {

    if (Object.keys(sessionStorage) && Object.keys(sessionStorage).length) {
      Object.keys(sessionStorage).forEach((key) => {
        if (key.includes("StaticHeader")) {
          sessionStorage.removeItem(key);
        }
      });
    }
    let value = option.value;
    if (selected_business_unit?.business_unit_id !== value) {
      setIsLoading(true);
      setAnchorEl(null);
      const _selected_business_unit = {
        business_unit_id: option.value,
        business_unit_name: option.label,
      };
      localStorage.setItem(
        "selected_business_unit",
        JSON.stringify(_selected_business_unit)
      );
      let user = JSON.parse(Cookies.get("user") || null);
      user = { ...user, ...user_metadata };
      await getSnopCalender(user.access, user.tenant_id, value);
      if (!user) return;
      const newUser = setUserLocalStorage(user, value);
      const userPermissions = newUser?.permissions;
      const roles = newUser?.roles;
      if (!userPermissions || !roles) return;
      const path = getPath(userPermissions, roles);
      if (user.tenant_id && _selected_business_unit?.business_unit_id) {
        const configurationResult = await getConfiguration(
          user.access,
          user.tenant_id,
          _selected_business_unit?.business_unit_id
        );
        const { permissions } = processNodeAndPermissions(SNOP);
        let isSandOpPermission = userPermissions.includes(permissions[0]);
        if (!isSandOpPermission) {
          redirectToPath(path);
          return;
        }
        if (configurationResult?.status === 200) {
          await getActiveSnop(
            user.access,
            user.tenant_id,
            _selected_business_unit?.business_unit_id
          );
          setUserCookies(newUser);
          redirectToPath(path);
        }
      } else {
        setUserCookies(newUser);
        redirectToPath(path);
      }
      setIsLoading(false);
    }
  };

  const handleClickMyProfile = () => {
    if (isAdmin) return true;
    navigate(ROUTES_PATH.MY_PROFILE);
  };

  const handleLogout = () => {
    logout();
    navigate(ROUTES_PATH.ROUTE);
  };

  return (
    <>
      {isLoading && <ProcessingLoader />}
      <Box
        onClick={(e) => {
          handleClick(e);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <StyledBadge
          overlap="rectangular" // Use rectangular to align with a square avatar
          anchorOrigin={{ vertical: "top", horizontal: "right" }} // Badge at top-right
          variant="dot"
        >
          <Avatar
            name={`${firstName} ${lastName}`}
            size="28" // Size remains the same
            textSizeRatio={1}
            textMarginRatio={0.25}
            className="avatar"

          />
        </StyledBadge>
      </Box>
      <SCAIPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          padding: "1rem",
          marginTop: "0.2rem",
        }}
        className="profile-dropdown-container"
      >
        <Box
          sx={{
            minWidth: "12.5rem",
            maxHeight: "auto",
          }}
        >
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              {!isAdmin &&
                <>
                  <ListItemButton
                    onClick={handleClickMyProfile}
                    sx={{
                      minHeight: "2.5rem",
                      justifyContent: "center",
                      px: "1.4rem",
                      py: "0rem",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        pr: "1rem",
                        justifyContent: "center",
                        color: theme.palette.primary.textColor, // Default icon color
                      }}
                    >
                      <Account color={theme.palette.primary.textColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary={"My Profile"}
                      primaryTypographyProps={{
                        fontSize: "1rem", // Set your desired font size
                        color: theme.palette.primary.textColor, // Optional: Add color here
                      }}
                    />
                  </ListItemButton>
                  <Divider
                    variant="middle"
                    sx={{
                      borderWidth: "0.09rem",
                      mt: "0.2rem",
                    }}
                  />

                  <ListItemButton
                    onClick={() => setBuExpand((prev) => !prev)}
                    sx={{
                      minHeight: "2.5rem",
                      justifyContent: "center",
                      px: "1.4rem",
                      py: "0rem",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        pr: "1rem",
                        justifyContent: "center",
                        color: theme.palette.primary.textColor, // Default icon color
                      }}
                    >
                      <BU color={theme.palette.primary.textColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary={selected_bu?.business_unit_name}
                      primaryTypographyProps={{
                        fontSize: "1rem", // Set your desired font size
                        color: theme.palette.primary.textColor, // Optional: Add color here
                      }}
                    />
                    {buOption?.length > 1 && (
                      <CustomTooltip
                        title={"Change BU"}
                        arrow
                        placement="left"
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            pl: "1rem",
                            justifyContent: "center",
                            color: theme.palette.primary.textColor, // Default icon color
                          }}
                        >
                          {buExpand ? (
                            <ArrowUp color={theme.palette.primary.textColor} />
                          ) : (
                            <ArrowDown color={theme.palette.primary.textColor} />
                          )}
                        </ListItemIcon>
                      </CustomTooltip>
                    )}
                  </ListItemButton>
                  {buOption?.length > 1 && (
                    <Collapse in={buExpand} timeout="auto" unmountOnExit>
                      {buOption?.map((bu) => (
                        <ListItemButton
                          key={bu?.value}
                          onClick={() => handleBuOption(bu)}
                          sx={{
                            minHeight: "2.5rem",
                            justifyContent: "center",
                            pl: "3.5rem",
                            py: "0rem",
                          }}
                        >
                          <ListItemText
                            primary={bu?.label}
                            primaryTypographyProps={{
                              fontSize: "1rem", // Set your desired font size
                              color: theme.palette.primary.textColor, // Optional: Add color here
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </Collapse>
                  )}
                  <Divider
                    variant="middle"
                    sx={{
                      borderWidth: "0.09rem",
                      mt: "0.2rem",
                    }}
                  />
                </>
              }

              <ListItemButton
                sx={{
                  minHeight: "2.5rem",
                  justifyContent: "center",
                  px: "1.4rem",
                  py: "0rem",
                }}
              >
                <ThemeSwitch
                  sx={{
                    mr: "0.5rem",
                  }}
                  checked={themeMode !== defaultTheme}
                  onChange={toggleMode}
                />
                <ListItemText
                  primary={themeMode[0]?.toUpperCase() + themeMode?.slice(1)}
                  primaryTypographyProps={{
                    fontSize: "1rem", // Set your desired font size
                    color: theme.palette.primary.textColor, // Optional: Add color here
                  }}
                />
              </ListItemButton>
              <Divider
                variant="middle"
                sx={{
                  borderWidth: "0.09rem",
                  mt: "0.2rem",
                }}
              />

              {/* Logout */}
              <ListItemButton
                onClick={handleLogout}
                sx={{
                  minHeight: "2.5rem",
                  justifyContent: "center",
                  px: "1.4rem",
                  py: "0rem",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    pr: "1rem",
                    justifyContent: "center",
                    color: theme.palette.primary.textColor, // Default icon color
                  }}
                >
                  <Signout color={theme.palette.primary.textColor} />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  primaryTypographyProps={{
                    fontSize: "1rem", // Set your desired font size
                    color: theme.palette.primary.textColor, // Optional: Add color here
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </SCAIPopover>
    </>
  );
}

export default MultiAvatar;
