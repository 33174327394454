import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import images from "../../constant/images";
import LoginForm from "./LoginForm";
import BannerText from "../../components/BannerText";

function MainBanner() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${images.banner1})`,
        backgroundSize: "17%",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
      }}
    >
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          padding: "4rem 1rem 1rem 1.5rem",
          minHeight: "calc(100vh - 18rem)",
        }}
      >
        <Grid container>
          <Grid item xs={4.3}>
          </Grid>
          <Grid item xs={7.7}>
            <Stack>
              <Typography variant="h4">Revolutionize Your Supply</Typography>
              <Typography variant="h4">Chain with Ai</Typography>
              <Box my={1} height="5rem">
                <BannerText />
              </Box>
              <Typography variant="body1" mt={"3rem"}>
                <Typography
                  color="secondary.main"
                  sx={{
                    display: "inline-block",
                    position: "relative",
                    mr: "1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  Touchless-
                  <Box
                    component="span"
                    sx={{ position: "relative", display: "inline-block" }}
                  >
                    AI
                    <Box
                      component="sup"
                      sx={{
                        position: "absolute",
                        top: "0.9em",
                        right: "-1.4em",
                        fontSize: "0.7em",
                      }}
                    >
                      TM
                    </Box>
                  </Box>
                </Typography>
                is an Automated Machine Learning framework designed to help
                organizations optimize their supply chain.
              </Typography>
              <Typography mt={"2rem"} mb={"2rem"} sx={{ lineHeight: "normal" }}>
                SCAI's AutoML Platform helps organizations
                <Typography color="secondary.main" sx={{ display: "inline" }}>
                   lift Revenue by 2-4%, reduce Cost of Operations by
                  5-10% and reduce Excess Inventory by 6-10%.
                </Typography>
              </Typography>
              <LoginForm />
              <Box mt={3}>
                <Box component="img" src={images.mouse} alt="mouse" />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MainBanner;
