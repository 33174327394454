import { ThemeProvider } from "@mui/material";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Cookies from "js-cookie";
import { createContext, default as React, useContext, useState } from 'react';
import { useSelectedMenu } from '../../hooks/useSelectedMenu';
import { defaultTheme } from "../../utils/const";
import { customTheme } from "../../utils/theme";
import SideNav from '../NavigationMenu/SideNav';
import TopNav from '../NavigationMenu/TopNav';
import "./styles.scss";

const DrawerHeader = styled('div')(({ theme, open }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-end' : 'center', // Align icon based on drawer state
    padding: open ? theme.spacing(0, 1.2) : 0, // No padding when closed
    height: '3.5rem', // Matches AppBar height
    color: open ? theme.palette.primary.activeText : theme.palette.primary.textColor, // Text/icon color
    '& .MuiIconButton-root': {
        color: open ? theme.palette.primary.activeText : theme.palette.primary.textColor, // Icon color matches the state
    },
    width: '100%', // Ensure it spans the full drawer width
}));

const ThemeContext = createContext();
export const useThemeToggle = () => useContext(ThemeContext);

const SCAILayout = ({ children }) => {
    const theme = JSON.parse(localStorage.getItem("settings"))?.platform?.theme || defaultTheme;
    const user = JSON.parse(Cookies.get("user") || null);
    const [mode, setMode] = useState(theme);
    const logo = user?.logo ?? null;
    const [open, setOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const toggleMode = () => {
        setMode(prev => prev === "dark" ? "light" : "dark");
        const themeObj = {
            platform: {
                theme: theme === "light" ? "dark" : "light"
            }
        }
        localStorage.setItem("settings", JSON.stringify(themeObj));
    }

    const handleCloseClick = () => {
        setOpen(false); // Close the drawer
    };

    //Logic for header
    const { selectedMenu, setSelectedMenu } = useSelectedMenu();
    return (
        <ThemeContext.Provider value={{ toggleMode }}>
            <ThemeProvider theme={customTheme[theme]}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    {/* Styled AppBar */}
                    <TopNav selectedMenu={selectedMenu} />
                    {/* Side nav drawer */}

                    <SideNav open={open} handleDrawerToggle={handleDrawerToggle} handleCloseClick={handleCloseClick} selectedMenu={selectedMenu} />
                    {/* Main Content */}
                    <Box component="main" sx={{
                        width: "100%",
                        flexGrow: 1,
                        //p: 3, // Padding for content
                        marginLeft: '4rem', // Offset for closed drawer width
                        overflowY: 'auto', // Enable vertical scrolling for main content
                        height: '100vh', // Full height minus AppBar height
                        transition: (theme) =>
                            theme.transitions.create('margin', {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                    }}>
                        <DrawerHeader />
                        {children}
                    </Box>
                </Box>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}

export default SCAILayout;