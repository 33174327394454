export const Toggle = ({ color }) => {
    return (
        <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.5 0.559021C1.67157 0.559021 1 1.23059 1 2.05902V9.87497C1 10.7034 1.67157 11.375 2.5 11.375H13.5C14.3284 11.375 15 10.7034 15 9.87497V2.05902C15 1.23059 14.3284 0.559021 13.5 0.559021H2.5Z"
                stroke={color}
            />
            <path
                d="M5.40039 0.875V10.875"
                stroke={color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const ArrowUp = ({ color }) => {
    return (
        <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.30105 4.27087L4.58438 1.55421C4.26355 1.23337 3.73855 1.23337 3.41772 1.55421L0.70105 4.27087"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const ArrowDown = ({ color }) => {
    return (
        <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.30105 0.729126L4.58438 3.44579C4.26355 3.76663 3.73855 3.76663 3.41772 3.44579L0.70105 0.729126"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const Snop = ({ color }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6667 5.5135V2.82016C14.6667 1.76016 14.24 1.3335 13.18 1.3335H10.4867C9.42667 1.3335 9 1.76016 9 2.82016V5.5135C9 6.5735 9.42667 7.00016 10.4867 7.00016H13.18C14.24 7.00016 14.6667 6.5735 14.6667 5.5135Z"
                stroke={color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.00004 5.68016V2.6535C7.00004 1.7135 6.57337 1.3335 5.51337 1.3335H2.82004C1.76004 1.3335 1.33337 1.7135 1.33337 2.6535V5.6735C1.33337 6.62016 1.76004 6.9935 2.82004 6.9935H5.51337C6.57337 7.00016 7.00004 6.62016 7.00004 5.68016Z"
                stroke={color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.00004 13.18V10.4867C7.00004 9.42667 6.57337 9 5.51337 9H2.82004C1.76004 9 1.33337 9.42667 1.33337 10.4867V13.18C1.33337 14.24 1.76004 14.6667 2.82004 14.6667H5.51337C6.57337 14.6667 7.00004 14.24 7.00004 13.18Z"
                stroke={color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M10 10.3335H14" stroke={color} stroke-linecap="round" />
            <path d="M10 13H14" stroke={color} stroke-linecap="round" />
        </svg>
    );
};

export const Config = ({ color }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.33337 14.6667V10"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.33337 3.33337V1.33337"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.6666 14.6666V12.6666"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.6666 6.00004V1.33337"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.33337 4.66671V8.66671C6.33337 9.40004 6.00004 10 5.00004 10H3.66671C2.66671 10 2.33337 9.40004 2.33337 8.66671V4.66671C2.33337 3.93337 2.66671 3.33337 3.66671 3.33337H5.00004C6.00004 3.33337 6.33337 3.93337 6.33337 4.66671Z"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.6666 7.33333V11.3333C13.6666 12.0667 13.3333 12.6667 12.3333 12.6667H11C9.99996 12.6667 9.66663 12.0667 9.66663 11.3333V7.33333C9.66663 6.6 9.99996 6 11 6H12.3333C13.3333 6 13.6666 6.6 13.6666 7.33333Z"
                stroke={color}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

export const Inventory = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66622 14.6666H3.33289C1.99955 14.6666 1.33289 13.9999 1.33289 12.6666V7.33325C1.33289 5.99992 1.99955 5.33325 3.33289 5.33325H6.66622V12.6666C6.66622 13.9999 7.33289 14.6666 8.66622 14.6666Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.73954 2.66675C6.68621 2.86675 6.66622 3.08675 6.66622 3.33341V5.33341H3.33289V4.00008C3.33289 3.26675 3.93289 2.66675 4.66622 2.66675H6.73954Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33289 5.33325V8.66659" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 5.33325V8.66659" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.3329 11.3333H9.99955C9.63289 11.3333 9.33289 11.6333 9.33289 11.9999V14.6666H11.9996V11.9999C11.9996 11.6333 11.6996 11.3333 11.3329 11.3333Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 8.66675V11.3334" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66711 12.6666V3.33325C6.66711 1.99992 7.33378 1.33325 8.66711 1.33325H12.6671C14.0004 1.33325 14.6671 1.99992 14.6671 3.33325V12.6666C14.6671 13.9999 14.0004 14.6666 12.6671 14.6666H8.66711C7.33378 14.6666 6.66711 13.9999 6.66711 12.6666Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const Order = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3337 11.94C15.3471 12.44 15.2137 12.9133 14.9737 13.32C14.8404 13.56 14.6604 13.78 14.4604 13.96C14.0004 14.3867 13.3937 14.6467 12.7204 14.6667C11.7471 14.6867 10.8871 14.1867 10.4137 13.42C10.1604 13.0267 10.0071 12.5533 10.0004 12.0533C9.9804 11.2133 10.3537 10.4533 10.9537 9.95333C11.4071 9.58 11.9804 9.34667 12.6071 9.33334C14.0804 9.30001 15.3004 10.4667 15.3337 11.94Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.6272 12.0201L12.3005 12.66L13.6939 11.3134" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.11353 4.96008L8.00018 8.36674L13.8468 4.98006" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.00024 14.4068V8.36011" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.4067 6.11338V9.88673C14.4067 9.92007 14.4067 9.9467 14.4 9.98003C13.9334 9.57337 13.3334 9.33339 12.6667 9.33339C12.04 9.33339 11.46 9.55339 11 9.92006C10.3867 10.4067 10 11.1601 10 12.0001C10 12.5001 10.14 12.9734 10.3867 13.3734C10.4467 13.4801 10.52 13.5801 10.6 13.6734L9.38005 14.3467C8.62005 14.7734 7.38004 14.7734 6.62004 14.3467L3.06004 12.3734C2.25337 11.9267 1.59338 10.8067 1.59338 9.88673V6.11338C1.59338 5.19338 2.25337 4.07339 3.06004 3.62672L6.62004 1.65337C7.38004 1.22671 8.62005 1.22671 9.38005 1.65337L12.94 3.62672C13.7467 4.07339 14.4067 5.19338 14.4067 6.11338Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export const Production = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.61329 1.50656L12.9533 3.84656C13.46 4.1199 13.46 4.8999 12.9533 5.17323L8.61329 7.51323C8.22663 7.7199 7.77329 7.7199 7.38663 7.51323L3.04663 5.17323C2.53996 4.8999 2.53996 4.1199 3.04663 3.84656L7.38663 1.50656C7.77329 1.2999 8.22663 1.2999 8.61329 1.50656Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.40671 6.75329L6.44004 8.77328C6.94004 9.02662 7.26004 9.53995 7.26004 10.1V13.9133C7.26004 14.4666 6.68004 14.82 6.18671 14.5733L2.15337 12.5533C1.65337 12.3 1.33337 11.7866 1.33337 11.2266V7.41328C1.33337 6.85995 1.91337 6.50662 2.40671 6.75329Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.5932 6.75329L9.55987 8.77328C9.05987 9.02662 8.73987 9.53995 8.73987 10.1V13.9133C8.73987 14.4666 9.31987 14.82 9.8132 14.5733L13.8465 12.5533C14.3465 12.3 14.6665 11.7866 14.6665 11.2266V7.41328C14.6665 6.85995 14.0865 6.50662 13.5932 6.75329Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const Raw = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33337 1.33325H2.49338C3.21338 1.33325 3.78004 1.95325 3.72004 2.66659L3.16671 9.30658C3.07337 10.3932 3.93337 11.3266 5.0267 11.3266H12.1267C13.0867 11.3266 13.9267 10.5399 14 9.58659L14.36 4.58659C14.44 3.47992 13.6 2.57992 12.4867 2.57992H3.88005" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8333 14.6667C11.2936 14.6667 11.6667 14.2936 11.6667 13.8333C11.6667 13.3731 11.2936 13 10.8333 13C10.3731 13 10 13.3731 10 13.8333C10 14.2936 10.3731 14.6667 10.8333 14.6667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.49996 14.6667C5.9602 14.6667 6.33329 14.2936 6.33329 13.8333C6.33329 13.3731 5.9602 13 5.49996 13C5.03972 13 4.66663 13.3731 4.66663 13.8333C4.66663 14.2936 5.03972 14.6667 5.49996 14.6667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 5.33325H14" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const Reports = ({ color }) => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5399 5.03158V11.2523C12.5399 13.1663 11.3978 13.8044 9.98779 13.8044H4.88363C3.4736 13.8044 2.33154 13.1663 2.33154 11.2523V5.03158C2.33154 2.95801 3.4736 2.47949 4.88363 2.47949C4.88363 2.87507 5.04311 3.23235 5.3047 3.49394C5.56629 3.75553 5.9236 3.91504 6.31917 3.91504H8.55224C9.34339 3.91504 9.98779 3.27064 9.98779 2.47949C11.3978 2.47949 12.5399 2.95801 12.5399 5.03158Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.2101 2.72559C10.2101 3.58454 9.51049 4.28418 8.65153 4.28418H6.22705C5.79757 4.28418 5.40964 4.111 5.12563 3.82699C4.84162 3.54298 4.66846 3.15507 4.66846 2.72559C4.66846 1.86663 5.36809 1.16699 6.22705 1.16699H8.65153C9.08101 1.16699 9.46894 1.34017 9.75295 1.62418C10.037 1.90819 10.2101 2.29611 10.2101 2.72559Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66846 7.58313H7.87679" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66846 9.91687H10.2101" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const Supply = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99988 9.33325H8.66654C9.39988 9.33325 9.99988 8.73325 9.99988 7.99992V1.33325H3.99988C2.99988 1.33325 2.12655 1.88658 1.67322 2.69991" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 11.3333C1.33337 12.4399 2.22671 13.3333 3.33337 13.3333H4.00004C4.00004 12.5999 4.60004 11.9999 5.33337 11.9999C6.06671 11.9999 6.66671 12.5999 6.66671 13.3333H9.33337C9.33337 12.5999 9.93337 11.9999 10.6667 11.9999C11.4 11.9999 12 12.5999 12 13.3333H12.6667C13.7734 13.3333 14.6667 12.4399 14.6667 11.3333V9.33325H12.6667C12.3 9.33325 12 9.03325 12 8.66659V6.66659C12 6.29992 12.3 5.99992 12.6667 5.99992H13.5267L12.3867 4.00659C12.1467 3.59326 11.7067 3.33325 11.2267 3.33325H10V7.99992C10 8.73325 9.40004 9.33325 8.66671 9.33325H8.00004" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.6667 14.6667C11.4031 14.6667 12 14.0697 12 13.3333C12 12.597 11.4031 12 10.6667 12C9.93033 12 9.33337 12.597 9.33337 13.3333C9.33337 14.0697 9.93033 14.6667 10.6667 14.6667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6667 8V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.6667 8Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 5.33325H5.33337" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 7.33325H4.00004" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 9.33325H2.66671" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export const Demand = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66671 5.33325H2.66671C1.93337 5.33325 1.33337 4.73325 1.33337 3.99992V2.66659C1.33337 1.93325 1.93337 1.33325 2.66671 1.33325H4.66671C5.40004 1.33325 6.00004 1.93325 6.00004 2.66659V3.99992C6.00004 4.73325 5.40004 5.33325 4.66671 5.33325Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.8666 4.66667H11.4666C11.0266 4.66667 10.6666 4.30666 10.6666 3.86666V2.80001C10.6666 2.36001 11.0266 2 11.4666 2H13.8666C14.3066 2 14.6666 2.36001 14.6666 2.80001V3.86666C14.6666 4.30666 14.3066 4.66667 13.8666 4.66667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.8666 9.66667H11.4666C11.0266 9.66667 10.6666 9.30666 10.6666 8.86666V7.80001C10.6666 7.36001 11.0266 7 11.4666 7H13.8666C14.3066 7 14.6666 7.36001 14.6666 7.80001V8.86666C14.6666 9.30666 14.3066 9.66667 13.8666 9.66667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 3.33325H10.6667" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.33337 3.33325V11.9999C8.33337 12.7333 8.93337 13.3333 9.66671 13.3333H10.6667" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.33337 8.33325H10.6667" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.8666 14.6667H11.4666C11.0266 14.6667 10.6666 14.3067 10.6666 13.8667V12.8C10.6666 12.36 11.0266 12 11.4666 12H13.8666C14.3066 12 14.6666 12.36 14.6666 12.8V13.8667C14.6666 14.3067 14.3066 14.6667 13.8666 14.6667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const Account = ({ color }) => {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99946 5.49973C6.15005 5.49973 7.08279 4.56699 7.08279 3.4164C7.08279 2.2658 6.15005 1.33306 4.99946 1.33306C3.84886 1.33306 2.91612 2.2658 2.91612 3.4164C2.91612 4.56699 3.84886 5.49973 4.99946 5.49973Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.57896 9.66667C8.57896 8.05417 6.97479 6.75 4.99979 6.75C3.02479 6.75 1.42063 8.05417 1.42063 9.66667" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const BU = ({ color }) => {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33336 9.66667H6.6667C8.3417 9.66667 8.6417 8.99583 8.7292 8.17917L9.0417 4.84583C9.1542 3.82917 8.86253 3 7.08336 3H2.9167C1.13753 3 0.845863 3.82917 0.958363 4.84583L1.27086 8.17917C1.35836 8.99583 1.65836 9.66667 3.33336 9.66667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.33333 3V2.66667C3.33333 1.92917 3.33333 1.33333 4.66667 1.33333H5.33333C6.66667 1.33333 6.66667 1.92917 6.66667 2.66667V3" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.83333 5.91667V6.33333C5.83333 6.3375 5.83333 6.3375 5.83333 6.34167C5.83333 6.79583 5.82917 7.16667 5 7.16667C4.175 7.16667 4.16667 6.8 4.16667 6.34583V5.91667C4.16667 5.5 4.16667 5.5 4.58333 5.5H5.41667C5.83333 5.5 5.83333 5.5 5.83333 5.91667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.02083 5.08333C8.05833 5.78333 6.95833 6.2 5.83333 6.34167" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.09171 5.19584C2.02921 5.83751 3.08755 6.22501 4.16671 6.34584" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Signout = ({ color }) => {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33333 5H6.66667" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.75 9.16667H6.25C8.33333 9.16667 9.16667 8.33334 9.16667 6.25V3.75C9.16667 1.66667 8.33333 0.833336 6.25 0.833336H3.75C1.66667 0.833336 0.833334 1.66667 0.833334 3.75V6.25C0.833334 8.33334 1.66667 9.16667 3.75 9.16667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Master = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.24707 5.91992H11.7471" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.25293 5.91992L4.75293 6.41992L6.25293 4.91992" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.24707 10.5869H11.7471" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.25293 10.5869L4.75293 11.0869L6.25293 9.58691" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.99967 14.6663H9.99967C13.333 14.6663 14.6663 13.333 14.6663 9.99967V5.99967C14.6663 2.66634 13.333 1.33301 9.99967 1.33301H5.99967C2.66634 1.33301 1.33301 2.66634 1.33301 5.99967V9.99967C1.33301 13.333 2.66634 14.6663 5.99967 14.6663Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Transaction = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66634 3.99967H9.33301C10.6663 3.99967 10.6663 3.33301 10.6663 2.66634C10.6663 1.33301 9.99967 1.33301 9.33301 1.33301H6.66634C5.99967 1.33301 5.33301 1.33301 5.33301 2.66634C5.33301 3.99967 5.99967 3.99967 6.66634 3.99967Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.33333 14.6664H6C2.66667 14.6664 2 13.333 2 10.6664V6.66635C2 3.62635 3.11333 2.79969 5.33333 2.67969" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.667 2.67969C12.887 2.79969 14.0003 3.61969 14.0003 6.66635V7.99969" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.52637 9.5957H12.5918C13.0794 9.5957 13.473 9.98932 13.473 10.4769V11.4522" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.4546 8.66699L8.52637 9.59522L9.4546 10.5235" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.473 13.6133H9.4076C8.91998 13.6133 8.52637 13.2197 8.52637 12.7321V11.7568" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.5449 14.542L13.4732 13.6138L12.5449 12.6855" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Logs = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33301 10H8.66634" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.33301 7.33301H10.6663" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 1.33301H10.6667C12.8867 1.45301 14 2.27301 14 5.32634V10.6663" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 7.33984V10.6532C2 13.3265 2.66667 14.6665 6 14.6665H8C8.11333 14.6665 9.89333 14.6665 10 14.6665" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14 10.667L10 14.667V12.667C10 11.3337 10.6667 10.667 12 10.667H14Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Resilience = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.8395 1.33301H11.1528C11.5862 1.33301 11.9728 1.34636 12.3195 1.39303C14.1662 1.59969 14.6662 2.46635 14.6662 4.83968V9.05302C14.6662 11.4264 14.1662 12.293 12.3195 12.4997C11.9728 12.5463 11.5928 12.5597 11.1528 12.5597H4.8395C4.40616 12.5597 4.0195 12.5463 3.67283 12.4997C1.82616 12.293 1.32617 11.4264 1.32617 9.05302V4.83968C1.32617 2.46635 1.82616 1.59969 3.67283 1.39303C4.0195 1.34636 4.40616 1.33301 4.8395 1.33301Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.05273 5.5459H11.5061" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.49316 9.40625H4.50649H11.5132" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66699 14.667H11.3337" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.79647 5.53353H4.80246" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.99666 5.53353H7.00265" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Twin = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.37971 3.06691C1.91304 0.760247 5.38638 0.760247 5.91305 3.06691C6.22638 4.42025 5.36638 5.56691 4.61971 6.28025C4.07304 6.80025 3.21304 6.79358 2.66638 6.28025C1.92638 5.56691 1.06638 4.42025 1.37971 3.06691Z" stroke={color} />
            <path d="M11.3138 10H13.3138C14.0471 10 14.6471 10.6 14.6471 11.3333V13.3333C14.6471 14.0667 14.0471 14.6667 13.3138 14.6667H11.3138C10.5805 14.6667 9.98047 14.0667 9.98047 13.3333V11.3333C9.98047 10.6 10.5805 10 11.3138 10Z" stroke={color} />
            <path d="M8.00013 3.33301H9.78679C11.0201 3.33301 11.5935 4.85967 10.6668 5.67301L5.34013 10.333C4.41346 11.1397 4.98679 12.6663 6.21346 12.6663H8.00013" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.65748 3.66634H3.66518" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.3245 12.3333H12.3322" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const SimulationWorld = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.33275 2H5.99941C4.69941 5.89333 4.69941 10.1067 5.99941 14H5.33275" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 2C10.6467 3.94667 10.9733 5.97333 10.9733 8" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 10.6667V10C3.94667 10.6467 5.97333 10.9733 8 10.9733" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 5.99941C5.89333 4.69941 10.1067 4.69941 14 5.99941" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.1333 14.2667C13.3115 14.2667 14.2667 13.3115 14.2667 12.1333C14.2667 10.9551 13.3115 10 12.1333 10C10.9551 10 10 10.9551 10 12.1333C10 13.3115 10.9551 14.2667 12.1333 14.2667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6667 14.6667L14 14" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export const Gauri = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.333 5.99967C11.333 8.57967 9.09301 10.6663 6.33301 10.6663L5.71301 11.413L5.34634 11.853C5.03301 12.2264 4.43301 12.1463 4.22634 11.6997L3.33301 9.73299C2.11967 8.87966 1.33301 7.52634 1.33301 5.99967C1.33301 3.41967 3.57301 1.33301 6.33301 1.33301C8.34634 1.33301 10.0863 2.44635 10.8663 4.04635C11.1663 4.63968 11.333 5.29967 11.333 5.99967Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6662 8.57353C14.6662 10.1002 13.8796 11.4536 12.6662 12.3069L11.7729 14.2735C11.5662 14.7202 10.9662 14.8069 10.6529 14.4269L9.66622 13.2402C8.05289 13.2402 6.61289 12.5269 5.71289 11.4135L6.33289 10.6669C9.09289 10.6669 11.3329 8.5802 11.3329 6.0002C11.3329 5.3002 11.1662 4.64021 10.8662 4.04688C13.0462 4.54688 14.6662 6.38686 14.6662 8.57353Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66699 6H8.00033" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Command = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66634 11.2999H4.13967C1.89301 11.2999 1.33301 10.7399 1.33301 8.49319V4.49319C1.33301 2.24652 1.89301 1.68652 4.13967 1.68652H11.1597C13.4063 1.68652 13.9663 2.24652 13.9663 4.49319" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66699 14.3131V11.2998" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33301 8.63379H6.66634" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.49316 14.3135H6.6665" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6668 8.5329V12.3396C14.6668 13.9196 14.2735 14.3129 12.6935 14.3129H10.3268C8.74685 14.3129 8.35352 13.9196 8.35352 12.3396V8.5329C8.35352 6.9529 8.74685 6.55957 10.3268 6.55957H12.6935C14.2735 6.55957 14.6668 6.9529 14.6668 8.5329Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.4967 12.1663H11.5027" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Disruption = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.333 5.99967C11.333 8.57967 9.09301 10.6663 6.33301 10.6663L5.71301 11.413L5.34634 11.853C5.03301 12.2264 4.43301 12.1463 4.22634 11.6997L3.33301 9.73299C2.11967 8.87966 1.33301 7.52634 1.33301 5.99967C1.33301 3.41967 3.57301 1.33301 6.33301 1.33301C8.34634 1.33301 10.0863 2.44635 10.8663 4.04635C11.1663 4.63968 11.333 5.29967 11.333 5.99967Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6662 8.57353C14.6662 10.1002 13.8796 11.4536 12.6662 12.3069L11.7729 14.2735C11.5662 14.7202 10.9662 14.8069 10.6529 14.4269L9.66622 13.2402C8.05289 13.2402 6.61289 12.5269 5.71289 11.4135L6.33289 10.6669C9.09289 10.6669 11.3329 8.5802 11.3329 6.0002C11.3329 5.3002 11.1662 4.64021 10.8662 4.04688C13.0462 4.54688 14.6662 6.38686 14.6662 8.57353Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66699 6H8.00033" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Profile = ({ color }) => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99886 7.99968C9.83981 7.99968 11.3322 6.50729 11.3322 4.66634C11.3322 2.82539 9.83981 1.33301 7.99886 1.33301C6.15791 1.33301 4.66553 2.82539 4.66553 4.66634C4.66553 6.50729 6.15791 7.99968 7.99886 7.99968Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.7258 14.6667C13.7258 12.0867 11.1591 10 7.99913 10C4.83913 10 2.27246 12.0867 2.27246 14.6667" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Client = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33338 14.6667H10.6667C13.3467 14.6667 13.8267 13.5933 13.9667 12.2867L14.4667 6.95333C14.6467 5.32667 14.18 4 11.3334 4H4.66671C1.82005 4 1.35338 5.32667 1.53338 6.95333L2.03338 12.2867C2.17338 13.5933 2.65338 14.6667 5.33338 14.6667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.3335 4.00004V3.46671C5.3335 2.28671 5.3335 1.33337 7.46683 1.33337H8.5335C10.6668 1.33337 10.6668 2.28671 10.6668 3.46671V4.00004" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33317 8.66667V9.33333C9.33317 9.34 9.33317 9.34 9.33317 9.34667C9.33317 10.0733 9.3265 10.6667 7.99984 10.6667C6.67984 10.6667 6.6665 10.08 6.6665 9.35333V8.66667C6.6665 8 6.6665 8 7.33317 8H8.6665C9.33317 8 9.33317 8 9.33317 8.66667Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.4335 7.33337C12.8935 8.45337 11.1335 9.12004 9.3335 9.34671" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.74658 7.51331C3.24658 8.53997 4.93992 9.15997 6.66658 9.35331" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Setup = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.3335 8.87927V7.70593C1.3335 7.0126 1.90016 6.43927 2.60016 6.43927C3.80683 6.43927 4.30016 5.58593 3.6935 4.53927C3.34683 3.93927 3.5535 3.15927 4.16016 2.8126L5.3135 2.1526C5.84016 1.83927 6.52016 2.02593 6.8335 2.5526L6.90683 2.67927C7.50683 3.72593 8.4935 3.72593 9.10016 2.67927L9.1735 2.5526C9.48683 2.02593 10.1668 1.83927 10.6935 2.1526L11.8468 2.8126C12.4535 3.15927 12.6602 3.93927 12.3135 4.53927C11.7068 5.58593 12.2002 6.43927 13.4068 6.43927C14.1002 6.43927 14.6735 7.00593 14.6735 7.70593V8.87927C14.6735 9.5726 14.1068 10.1459 13.4068 10.1459C12.2002 10.1459 11.7068 10.9993 12.3135 12.0459C12.6602 12.6526 12.4535 13.4259 11.8468 13.7726L10.6935 14.4326C10.1668 14.7459 9.48683 14.5593 9.1735 14.0326L9.10016 13.9059C8.50016 12.8593 7.5135 12.8593 6.90683 13.9059L6.8335 14.0326C6.52016 14.5593 5.84016 14.7459 5.3135 14.4326L4.16016 13.7726C3.5535 13.4259 3.34683 12.6459 3.6935 12.0459C4.30016 10.9993 3.80683 10.1459 2.60016 10.1459C1.90016 10.1459 1.3335 9.5726 1.3335 8.87927Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const User = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10622 7.24671C6.03955 7.24004 5.95955 7.24004 5.88622 7.24671C4.29955 7.19337 3.03955 5.89337 3.03955 4.29337C3.03955 2.66004 4.35955 1.33337 5.99955 1.33337C7.63288 1.33337 8.95955 2.66004 8.95955 4.29337C8.95288 5.89337 7.69288 7.19337 6.10622 7.24671Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9402 2.66663C12.2335 2.66663 13.2735 3.71329 13.2735 4.99996C13.2735 6.25996 12.2735 7.28663 11.0268 7.33329C10.9735 7.32663 10.9135 7.32663 10.8535 7.33329" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.77299 9.70663C1.15965 10.7866 1.15965 12.5466 2.77299 13.62C4.60632 14.8466 7.61299 14.8466 9.44632 13.62C11.0597 12.54 11.0597 10.78 9.44632 9.70663C7.61966 8.48663 4.61299 8.48663 2.77299 9.70663Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.2266 13.3334C12.7066 13.2334 13.1599 13.04 13.5332 12.7534C14.5732 11.9734 14.5732 10.6867 13.5332 9.90671C13.1666 9.62671 12.7199 9.44004 12.2466 9.33337" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Role = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 12.5733H11.4933C10.96 12.5733 10.4533 12.7799 10.08 13.1533L8.93998 14.2799C8.41998 14.7933 7.57334 14.7933 7.05334 14.2799L5.91333 13.1533C5.54 12.7799 5.02667 12.5733 4.5 12.5733H4C2.89333 12.5733 2 11.6866 2 10.5933V3.31995C2 2.22661 2.89333 1.33997 4 1.33997H12C13.1067 1.33997 14 2.22661 14 3.31995V10.5933C14 11.68 13.1067 12.5733 12 12.5733Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.00012 6.66671C8.858 6.66671 9.55347 5.97124 9.55347 5.11336C9.55347 4.25548 8.858 3.56006 8.00012 3.56006C7.14224 3.56006 6.44678 4.25548 6.44678 5.11336C6.44678 5.97124 7.14224 6.66671 8.00012 6.66671Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.6668 10.44C10.6668 9.24002 9.4735 8.26672 8.00016 8.26672C6.52683 8.26672 5.3335 9.24002 5.3335 10.44" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const DS = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 7.26671V2.73337C14.6667 1.73337 14.24 1.33337 13.18 1.33337H10.4867C9.42667 1.33337 9 1.73337 9 2.73337V7.26671C9 8.26671 9.42667 8.66671 10.4867 8.66671H13.18C14.24 8.66671 14.6667 8.26671 14.6667 7.26671Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.6667 13.2666V12.0666C14.6667 11.0666 14.24 10.6666 13.18 10.6666H10.4867C9.42667 10.6666 9 11.0666 9 12.0666V13.2666C9 14.2666 9.42667 14.6666 10.4867 14.6666H13.18C14.24 14.6666 14.6667 14.2666 14.6667 13.2666Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.00016 8.73337V13.2667C7.00016 14.2667 6.5735 14.6667 5.5135 14.6667H2.82016C1.76016 14.6667 1.3335 14.2667 1.3335 13.2667V8.73337C1.3335 7.73337 1.76016 7.33337 2.82016 7.33337H5.5135C6.5735 7.33337 7.00016 7.73337 7.00016 8.73337Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.00016 2.73337V3.93337C7.00016 4.93337 6.5735 5.33337 5.5135 5.33337H2.82016C1.76016 5.33337 1.3335 4.93337 1.3335 3.93337V2.73337C1.3335 1.73337 1.76016 1.33337 2.82016 1.33337H5.5135C6.5735 1.33337 7.00016 1.73337 7.00016 2.73337Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}



