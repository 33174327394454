// import { ReactLenis } from "lenis/dist/lenis-react";
import { ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { customThemePublic } from "../utils/theme";
// import Header from "../components/header";
import MyContext from "../provider/MyContext";

function PublicRoute({ children }) {
   const [LenisComponent, setLenisComponent] = useState(null);

  useEffect(() => {
    import("lenis/dist/lenis-react").then((module) => {
      setLenisComponent(() => module.ReactLenis);
    });
  }, []);
  
  return (
    <ThemeProvider theme={customThemePublic.light}>
      <MyContext>
       {LenisComponent ? (
          <LenisComponent root option={{ lerp: 1 }}>{children}</LenisComponent>
        ) : (
          children
        )}
      </MyContext>
    </ThemeProvider>
  );
}

export default PublicRoute;
