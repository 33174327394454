import React from "react";
import images from "../../constant/images";
import { Box, Stack, Typography } from "@mui/material";

function AboutLanding() {
  return (
    <Box
      sx={{
        maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
        margin: "0px auto",
        padding: "0rem 0rem 1.5rem 1rem",
      }}
    >
      <Box
        sx={{
          borderBottom: (theme) => `.1rem solid ${theme.palette.divider}`,
        }}
      >
        <Box maxWidth="75rem" mb={3}>
          <Stack direction="row" gap={1} alignItems="center">
            <Box
              width="2rem"
              component="img"
              src={images.building}
              alt="building"
            />
            <Typography variant="body2">Industry Pain-points</Typography>
          </Stack>
          <Typography variant="h3" mt={1} mb={1}>
            Biggest Supply Chain Pain Points Today
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "1.3rem" }}>
            <Typography
              variant="body2"
              color="secondary.main"
              sx={{
                fontSize: "1.3rem",
                display: "inline-block",
                fontWeight: "bold",
              }}
            >
              In today's
            </Typography>
             supply chain process, business operations require more flexibility
            with innovative business solutions to scale up their operations
            which requires real time information and intelligence
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutLanding;
