import { Box } from "@mui/material";
import React from "react";
import images from "../../constant/images";

function FeatureLogo() {
  return (
    <Box>
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          textAlign: "center",
          position: "relative",
          width: "100%",
          height: "100%",
          px: "1rem",
        }}
      >
        <Box
          component="img"
          src={images.lightLine}
          alt="light line"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          component="img"
          src={images.lightCircle}
          alt="light"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
        <Box
          component="img"
          src={images.light}
          alt="light"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </Box>
    </Box>
  );
}

export default FeatureLogo;
