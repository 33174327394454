import { motion } from "framer-motion";

const InfiniteTextAnimation = ({ text, style }) => {
  return (
    <motion.div
      key={0}
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        ...style,
      }}
      initial={{ width: 0 }}
      animate={{
        width: ["0%", "100%", "0%"],
        opacity: 1,
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      }}
    >
      {text}
    </motion.div>
  );
};

export default InfiniteTextAnimation;
