import { Command, Disruption, Gauri, Resilience, SimulationWorld, Snop } from "../../../assets/svg-icons";
import { processNodeAndPermissions } from "../../../helper/com-helper";
import { DT } from "./DT";

const RISKAI_OBJECT = {
  name: "Risk Management & Digital Twin",
  route: "/riskai/reports",
  permissions: [],
  child: {
    "Command Center": {
      name: "Command Center",
      shortName: "CC",
      route: "/riskai/reports",
      icon: Command,
      permissions: ["RISK_DASHBOARD_VIEW"],
    },
    "S&OE Dashboard": {
      name: "S&OE Dashboard",
      shortName: "S&OE",
      route: "/riskai/soe",
      icon: Snop,
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
    "Resilience Monitoring": {
      name: "Resilience Monitoring",
      shortName: "RM",
      route: "/riskai/resilienceoverall",
      icon: Resilience,
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
    "Disruption Management": {
      name: "Disruption Management",
      shortName: "DM",
      route: "/riskai",
      icon: Disruption,
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
    GAURI: {
      name: "GAURI",
      shortName: "Gauri",
      route: "/riskai/gauri",
      icon: Gauri,
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
    "Digital Twin": DT,
    "Simulation World Engine": {
      name: "Simulation World Engine",
      shortName: "SWE",
      route: "/riskai/worldengine",
      icon: SimulationWorld,
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
  },

  extraRoutes: [
    {
      route: "/riskai/riskdetails",
      permissions: ["RISK_COMMAND_CENTRE_VIEW"],
    },
  ],
};

processNodeAndPermissions(RISKAI_OBJECT);
export const RISKAI = RISKAI_OBJECT;
