import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function Monitor({ data }) {
  return (
    <>
      {data.map(({ title, par, list }, idx) => {
        return (
          <Grid
            key={idx}
            container
            alignItems="center"
            py={3}
            sx={{
              borderTop: (theme) =>
                `.1rem solid ${theme.palette.custom.borderColor}`,
            }}
          >
            <Grid item xs={7}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "2.2rem",
                    color: (theme) => theme.palette.secondary.main,
                    fontFamily: (theme) =>
                      theme.palette.customFontFamily.secondary,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.2rem",
                    color: (theme) => theme.palette.secondary.light,
                    fontFamily: (theme) =>
                      theme.palette.customFontFamily.secondary,
                    mt: "1rem",
                  }}
                >
                  {par}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box>
                {list.map((listItem, idx) => {
                  return (
                    <Typography
                      key={idx}
                      sx={{
                        fontSize: "1.2rem",
                        color: (theme) => theme.palette.secondary.light,
                        fontFamily: (theme) =>
                          theme.palette.customFontFamily.secondary,
                      }}
                    >
                      {listItem}
                    </Typography>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default Monitor;
