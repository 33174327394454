import backIcon from "../assets/back_icon.svg";
import comLogo from "../assets/com-log.svg";
import dropDownIcon from "../assets/dropDownIcon.svg";
import lockHide from "../assets/lock_hide.svg";
import lockUnhide from "../assets/lock_unhide.svg";
import logo from "../assets/svg-icons/new-logo.svg";
import mouse from "../assets/svg-icons/mouse.svg";
import banner1 from "../assets/svg-icons/banner1.svg";
import okta from "../assets/svg-icons/okta.svg";
import building from "../assets/svg-icons/buildings.svg";
import circleBanner from "../assets/svg-icons/circle-banner.svg";
import scaiBanner from "../assets/svg-icons/SCAI.svg";
import light from "../assets/svg-icons/light.svg";
import lightLine from "../assets/svg-icons/light-line.svg";
import lightCircle from "../assets/svg-icons/light-circle.svg";
import card1 from "../assets/svg-icons/card1.svg";
import card2 from "../assets/svg-icons/card2.svg";
import card3 from "../assets/svg-icons/card3.svg";
import card4 from "../assets/svg-icons/card4.svg";
import card5 from "../assets/svg-icons/card5.svg";
import touch from "../assets/svg-icons/touch.svg";
import innovate from "../assets/svg-icons/innovate.svg";
import monitor from "../assets/svg-icons/monitor.svg";
import text1 from "../assets/svg-icons/text1.svg";
import button1 from "../assets/svg-icons/button.svg";

const images = {
  comLogo,
  backIcon,
  lockHide,
  lockUnhide,
  dropDownIcon,
  logo,
  mouse,
  banner1,
  okta,
  building,
  circleBanner,
  scaiBanner,
  light,
  lightLine,
  lightCircle,
  card1,
  card2,
  card3,
  card4,
  card5,
  touch,
  innovate,
  monitor,
  text1,
  button1,
};

export default images;
