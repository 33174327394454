import React from "react";
import CustomAccordion from "../CustomAccordion";
import { aboutAccordionData } from "../../constant/constant";
import { Box, Grid } from "@mui/material";
import images from "../../constant/images";
import { motion, useTransform } from "framer-motion";

function ForwardLooking({ scrollYProgress, isProgress }) {
  const rotate = useTransform(scrollYProgress, [0, 1], ["0deg", "190deg"]);
  return (
    <Box
      id="about"
      sx={{
        maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
        margin: "0px auto",
        padding: "0rem 1rem",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "16rem",
            height: "47rem",
            top: "7rem",
            left: "-3rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${images.scaiBanner})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "hidden",
            }}
          />
        </Box>
        <Grid container pt={6}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6.1}>
            <Box sx={{ textAlign: "right" }} mr={5}>
              <CustomAccordion
                scrollYProgress={scrollYProgress}
                data={aboutAccordionData}
                isProgress={isProgress}
              />
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            width: "46%",
            height: "97%",
            top: "3rem",
            right: "-20%",
          }}
        >
          <motion.img
            src={images.circleBanner}
            alt="Rotating"
            style={{
              width: "94%",
              height: "80%",
              rotate: isProgress ? rotate : "none",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ForwardLooking;
