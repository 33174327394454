import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteTextAnimation from "../BannerText/InfiniteTextAnimation";
const phrases = [1, 2, 3];

function FeatureLanding() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  return (
    <Box>
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          padding: "2rem 1rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexFlow: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexFlow: "column",
              alignItems: "end",
              width: "max-content",
            }}
          >
            <Typography variant="h5">
              SCAI empowers planners with actionable insights to ensure
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              them with actionable insights to ensure the{" "}
              <Typography
                variant="h5"
                component="span"
                color="primary.main"
                sx={{ display: "flex", ml: ".5rem", alignItems: "center" }}
              >
                right{" "}
                <Typography
                  variant="h5"
                  component="span"
                  color="primary.main"
                  sx={{
                    width: "13rem",
                    ml: ".5rem",
                  }}
                >
                  {index === 0 && (
                    <InfiniteTextAnimation
                      text="product"
                      style={{
                        fontSize: "3.5rem",
                      }}
                    />
                  )}
                  {index === 1 && (
                    <InfiniteTextAnimation
                      text="place"
                      style={{
                        fontSize: "3.5rem",
                      }}
                    />
                  )}
                  {index === 2 && (
                    <InfiniteTextAnimation
                      text="time"
                      style={{
                        fontSize: "3.5rem",
                      }}
                    />
                  )}
                </Typography>
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FeatureLanding;
