import { useEffect } from "react";

const MicroFrontend = (props) => {
  const renderMicroFrontend = () => {
    const { name, window, history } = props;

    window[`createRoot${name}`] &&
      window[`createRoot${name}`](`${name}-container`, history);
  };

  useEffect(() => {
    const { name, host, document } = props;
    const scriptId = `micro-frontend-script-${name}`;
    const cssLinkId = `micro-frontend-css-${name}`;
    const existingScript = document.getElementById(scriptId);

    if(existingScript) {
      window.location.reload();
      return;
    }

    renderMicroFrontend();
    
    fetch(`${host}/${name}/asset-manifest.json`)
      .then((res) => res.json())
      .then(
        (manifest) => {
          const script = document.createElement("script");
          script.id = scriptId;
          script.crossOrigin = "cors";
          script.src = `${host}${manifest["files"]["main.js"]}`;
          document.head.appendChild(script);

          const css = document.createElement("link");
          css.id = cssLinkId;
          css.rel = "stylesheet";
          css.crossOrigin = "cors";
          css.href = `${host}${manifest["files"]["main.css"]}`;
          document.head.appendChild(css);
        },
        () => {
          const currContainer = document.getElementById(name);
          if (currContainer) {
            currContainer.innerHTML =
              "Requested module is not available right now, please try later !";
            currContainer.classList.add(
              "alert-danger",
              "alert",
              "fade",
              "show"
            );
          }
        }
      );
    return () => {
      const { name, window } = props;
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    };
  }, [props]);
  
  return null;
};

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
