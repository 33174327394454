import { Demand } from "../../../../assets/svg-icons";
export const DPAI = {
  name: "Demand Planning",
  shortName: "Demand",
  route: null,
  icon: Demand,
  permissions: [],
  child: {
    Forecasting: {
      name: "Forecasting",
      route: "/dp/forecast",
      permissions: ["FORECAST_VIEW"],
    },
    Collaboration: {
      name: "Collaboration",
      route: "/dp/collaboration",
      permissions: ["COLLABORATION_VIEW"],
    },
    Analytics: {
      name: "Analytics",
      route: "/dp/analytics",
      permissions: ["DEMANDANALYTICS_VIEW"],
    },
    Simulate: {
      name: "Simulate",
      route: "/dp/simulate/scenario",
      permissions: ["SIMULATION_SCENARIO_VIEW"],
    },
    Reports: {
      name: "Reports",
      route: "/dp/reports",
      permissions: ["DPAI_REPORTS_VIEW"],
    },
  },
  extraRoutes: [
    {
      route: "/dp/simulate/scenario/edit",
      permissions: ["SIMULATION_SCENARIO_CREATE", "SIMULATION_SCENARIO_UPDATE"],
    },
    {
      route: "/dp/simulate/scenario/create",
      permissions: ["SIMULATION_SCENARIO_CREATE", "SIMULATION_SCENARIO_UPDATE"],
    },
    {
      route: "/dp/simulate/scenario/case",
      permissions: ["SIMULATION_SCENARIO_VIEW"],
    },
    {
      route: "/dp/simulate/scenario/case/edit",
      permissions: ["SIMULATION_SCENARIO_CASE_UPDATE"],
    },
    {
      route: "/dp/simulate/scenario/case/create",
      permissions: ["SIMULATION_SCENARIO_CREATE"],
    },
    {
      route: "/dp/simulate/scenario/case/compare",
      permissions: ["SIMULATION_SCENARIO_VIEW"],
    },
    {
      route: "/dp/reports/details",
      permissions: ["SIMULATION_SCENARIO_VIEW"],
    },
  ],
};
