// hooks/useMenuData.js
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { APPBAR_MENU } from "../constant/constant";
import { accessibleMenus } from "../helper/com-helper";
import { APP_MENU, BAR_MENU } from "../utils/const";
import { ROUTES_PATH } from "../utils/EndPoints";
import { PROFILE_ROUTE } from "../utils/RouteHelper/SCAI";

// Custom hook to manage and fetch menu data
const useMenuData = (selectedMenu) => {
  const location = useLocation();
  const [menuData, setMenuData] = useState({
    parent: [],
    menu: [],
    subMenu: [],
  });

  useEffect(() => {
    const parentMenu =
      selectedMenu?.parent === APPBAR_MENU?.ID
        ? []
        : accessibleMenus(Object.values(BAR_MENU));
    const selectedBarMenu = BAR_MENU?.[APP_MENU?.[selectedMenu?.parent]];

    const _mainMenu = selectedBarMenu?.child ?? {};
    const mainMenuItems =
      location?.pathname === ROUTES_PATH.MY_PROFILE
        ? PROFILE_ROUTE
        : accessibleMenus(Object.values(_mainMenu));

    const selectedMenuChild = _mainMenu[selectedMenu?.menu]?.child ?? {};
    const subMenuItems = accessibleMenus(Object.values(selectedMenuChild));

    setMenuData({
      parent: parentMenu,
      menu: mainMenuItems,
      subMenu: subMenuItems,
    });
  }, [selectedMenu, accessibleMenus]);

  return menuData;
};

export default useMenuData;
