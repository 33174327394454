import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import images from "../../constant/images";
import { isDemoAvl } from "../../utils/ApiHandler";

function HowItWork() {
  return (
    <Box bgcolor="primary.main" sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          width: "18%",
          height: "100%",
          top: "9rem",
          right: "0",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${images.innovate})`,
            backgroundSize: "contain",
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: (theme) => theme.palette.widthCustom.maxWidthContainer,
          margin: "0px auto",
          px: "1rem",
        }}
      >
        <Stack direction="row" alignItem="center" gap={"2.5rem"} py={"5rem"}>
          <Box>
            <Typography variant="h4" color="common.white" fontWeight="bold">
              Innovate
            </Typography>
            <Typography variant="h4" color="common.white" fontWeight="bold">
              with
            </Typography>
            <Typography variant="h4" color="common.white" fontWeight="bold">
              AI
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: "auto !important",
              background: (theme) => theme.palette.common.white,
              opacity: 1,
            }}
          />
          <Stack justifyContent="space-around">
            <Box>
              <Typography variant="h5" fontSize="2.6rem">
                let’s shape the future
              </Typography>
              <Typography variant="h5" fontSize="2.6rem">
                together!
              </Typography>
            </Box>
            <Box>
              {isDemoAvl && (
                <Box component="a" href="#demo">
                  <Box
                    sx={{
                      borderRadius: "1.2rem",
                      maxWidth: "85%",
                      boxShadow: (theme) => theme.palette.shadow.innovate,
                    }}
                    component="img"
                    src={images.button1}
                    alt="button1"
                  />
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default HowItWork;
